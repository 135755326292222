import React, { useState } from "react";
import { Typography, Grid, Divider, Button, Collapse, Box } from "@mui/material";
import PropTypes from "prop-types";

// Local Imports
import HighLogo from "images/logos/High.webp";
import Triangleup from "images/Icons/Triangleup.webp";
import Triangledown from "images/Icons/Triangledown.webp";
import LowLogo from "images/logos/Low.webp";
import DetailAsset from "./DetailAsset";
import AssetProgressIndicator from "./AssetProgressIndicator";

function AssetDetailView({ asset }) {
  const [openDetails, setOpenDetails] = useState(false);
  const [openCopyPopup, setOpenCopyPopup] = useState(false);
  const [anchorCopy, setAnchorCopy] = useState(null);

  const handleClickpop = (event) => {
    setAnchorCopy(event.currentTarget);
    setOpenCopyPopup(true);
    setTimeout(() => {
      setOpenCopyPopup(false);
    }, 1500);

    const copyLInk = `${process.env.REACT_APP_DOMAIN_URL}/asset/detail/${asset.task_id}`;
    navigator.clipboard.writeText(copyLInk);
  };

  return (
    <>
      <Grid container>
        <Grid item md={2.5}>
          <Box sx={{ paddingRight: "1.5rem" }}>
            <Typography className="b-asset-detail-view-container__bodyText" variant="body">
              {asset.custom_status_label}
            </Typography>

            <Button
              className={
                !openDetails
                  ? "vmore"
                  : "vmore b-asset-detail-view-container__view-more-less-container__view-m-hide"
              }
              sx={{ cursor: "pointer", display: "-webkit-flex", paddingLeft: "0px" }}
              item
              pt="12px"
              pl="0px"
              md={1.2}
              onClick={() => {
                setOpenDetails(true);
              }}>
              <Typography
                sx={{
                  color: "#4a4a4a",
                  fontFamily: "GothamBook",
                  cursor: "pointer",
                  lineHeight: "1.5",
                  letterSpacing: "0.00938em",
                  fontSize: "14px",
                  fontWeight: "400",
                }}>
                VIEW MORE
              </Typography>

              <img
                src={Triangleup}
                alt="hp"
                className="b-asset-detail-view-container__view-more-less-container__image-arrow-view-more-less"
              />
            </Button>
            <Button
              className={
                openDetails
                  ? "vless"
                  : "vless b-asset-detail-view-container__view-more-less-container__view-m-hide"
              }
              sx={{ cursor: "pointer", display: "-webkit-flex", paddingLeft: "0px" }}
              item
              pt="12px"
              pl="0px"
              md={1.2}
              onClick={() => {
                setOpenDetails(false);
              }}>
              <Typography
                sx={{
                  color: "#4a4a4a",
                  fontFamily: "GothamBook",
                  cursor: "pointer",
                  lineHeight: "1.5",
                  letterSpacing: "0.00938em",
                  fontSize: "14px",
                  fontWeight: "400",
                }}>
                VIEW LESS
              </Typography>

              <img
                src={Triangledown}
                alt="hp"
                className="b-asset-detail-view-container__view-more-less-container__image-arrow-view-more-less"
              />
            </Button>
          </Box>
        </Grid>

        <Grid item md={9.5}>
          <Grid container>
            <Grid item md={12} sx={{ display: "-webkit-flex" }}>
              {asset.task_importance === "Low" && (
                <img src={LowLogo} alt="lp" className="b-asset-detail-view-container__logo-image" />
              )}
              {asset.task_importance === "High" && (
                <img
                  src={HighLogo}
                  alt="lp"
                  className="b-asset-detail-view-container__logo-image-icon"
                />
              )}
              <Typography
                sx={{
                  fontFamily: "GothamMedium",
                  color: "#4a4a4a",
                  marginTop: "3px",
                  fontSize: "14px",
                  lineHeight: "1.9",
                  letterSpacing: "0.00938em",
                  marginRight: 10,
                }}>
                {asset.title}
              </Typography>
            </Grid>

            <AssetProgressIndicator asset={asset} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          sx={{
            borderLeft: 2,
            borderColor: "#5eaee0",
            zIndex: 100,
            background: "white",
          }}
          item
          md={12}>
          <Collapse in={openDetails}>
            <DetailAsset
              asset={asset}
              handleClickpop={handleClickpop}
              openCopyPopup={openCopyPopup}
              anchorCopy={anchorCopy}
            />
          </Collapse>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            height: "1px",
            paddingTop: "29px",
            paddingBottom: "30px",
            // background: "red",
          }}
        />
      </Grid>
    </>
  );
}

AssetDetailView.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default AssetDetailView;
