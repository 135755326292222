import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Heading from "components/HeadingComponent/Heading";

function GetStartedDetailDescription({
  description,
  descriptionStyle = "",
  variant = "",
  title,
  image,
  rowDirection = "",
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: rowDirection }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: !rowDirection && 5,
          justifyContent: "center",
        }}>
        <Heading
          titleStyle="b-listing-container__bluebox__boxcolor__Utilize-the-Filter-F"
          variant="p"
          title={title}
        />
        <Typography variant={variant} className={descriptionStyle}>
          {description}
        </Typography>
      </Box>
      <Box sx={rowDirection && { paddingRight: 5 }}>
        <img src={image} alt={title} />
      </Box>
    </Box>
  );
}
GetStartedDetailDescription.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string]).isRequired,
  descriptionStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string]).isRequired,
  image: PropTypes.oneOfType([PropTypes.string]).isRequired,
  rowDirection: PropTypes.oneOfType([PropTypes.string]).isRequired,
};
export default GetStartedDetailDescription;
