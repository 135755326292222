import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, LinearProgress, Tooltip, Typography } from "@mui/material";

// Local Imports
import HighLogo from "images/logos/High.webp";
import LowLogo from "images/logos/Low.webp";
import { Link } from "react-router-dom";
import CompleteProgressPopover from "components/Popovers/CompleteProgressPopover";

function AssetsProgressBarStatus(props) {
  const {
    title,
    description,
    barColor,
    taskPriority,
    data,
    breadcrumbsDetail,
    curentPageLink,
    completedAsset = false,
    percent,
    barValue = 0,
  } = props;

  const [popOverTitle, setPopOverTitle] = useState("");
  const [popOverDuration, setPopOverDuration] = useState("");
  const [progressAnchor, setProgressAnchor] = useState(null);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleHoverPopoverOpen = (event, timeline) => {
    const totalDays = data.timeline_entries
      .filter((a) => a.info === timeline.info)
      .reduce((prev, current) => {
        return prev + +current.days;
      }, 0);
    setProgressAnchor(event.currentTarget);
    setCoordinates([event.clientX, event.clientY]);
    setPopOverDuration(totalDays);
    setPopOverTitle(timeline.info);
  };

  const handleHoverPopoverOpenComplete = (event, newData) => {
    setProgressAnchor(event.currentTarget);
    setCoordinates([event.clientX, event.clientY]);
    setPopOverDuration(newData.days_till_today);
    setPopOverTitle(newData.title);
  };

  const handleHoverPopoverClose = () => {
    setProgressAnchor(null);
  };

  const LinkStyle = {
    fontFamily: "GothamMedium !important",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: " -0.17px",
    color: isHover ? "#6cace4" : "#4a4a4a",
    paddingLeft: 2,
    // backgroundColor: "red",
  };

  const os = navigator.userAgent;
  let finalOs;
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  }
  const progressLineStartActive =
    finalOs === "Windows"
      ? "detail-view-container__grid_progress-border"
      : "detail-view-container__grid_progress-mac-border";
  const progressLineDueActive =
    finalOs === "Windows"
      ? "detail-view-container__grid_progress1-border"
      : "detail-view-container__grid_progress1-mac-border";
  const progressLineDelaytActive =
    finalOs === "Windows"
      ? "detail-view-container__grid_progress2-border"
      : "detail-view-container__grid_progress2-mac-border";

  if (completedAsset) {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Box className="asset-progress__select-button" sx={{ display: "flex" }}>
          {(taskPriority === "High" || taskPriority === "Low") && (
            <img
              src={taskPriority === "High" ? HighLogo : LowLogo}
              alt="hp"
              className="asset-progress__logo-image"
            />
          )}
          <Typography
            sx={{
              textDecoration: "underline",
              fontFamily: "GothamMedium !important",
            }}>
            <Link
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              to={curentPageLink}
              state={{ assetDetail: data, brudcrum: breadcrumbsDetail }}
              style={LinkStyle}>
              {completedAsset ? `${title}` : `${description}`}
            </Link>
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <LinearProgress
            sx={{
              width: `${barValue}%`,
              height: 8,
              "& .MuiLinearProgress-bar": {
                backgroundColor: `${barColor}`,
              },
            }}
            value={100}
            onMouseEnter={(event) => handleHoverPopoverOpenComplete(event, data)}
            onMouseLeave={handleHoverPopoverClose}
            variant="determinate"
            aria-owns="mouse-over-popover"
            aria-haspopup="true"
          />

          <div>
            <Tooltip
              title={
                <Typography fontFamily="GothamBook" fontSize={12}>
                  Represents actual number of business days vs SOP timeline estimated number of
                  business days.
                </Typography>
              }>
              <Typography
                sx={{
                  fontFamily: "GothamMedium",
                  fontSize: "9px",
                  lineHeight: "1.67",
                  letterSpacing: " -0.17px",
                  color: "#262162",
                  width: "4rem",
                  paddingLeft: "0.5rem",
                  marginTop: "-3px",
                  display: "flex",
                  alignItems: "flex-end",
                }}>
                {`(${data.days_till_today}/${data.total_days} Days)`}
              </Typography>
            </Tooltip>
          </div>
        </Box>

        {/* Hover Popover */}
        <CompleteProgressPopover
          hoveropen={progressAnchor}
          onClose={handleHoverPopoverClose}
          label={popOverTitle}
          duration={popOverDuration}
          coordinates={coordinates}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      {!completedAsset && (
        <Grid item md={2.5} className="asset-progress" sx={{ paddingBottom: "20px" }}>
          <Typography className="asset-progress__detail" mr={2.5}>
            {title}
          </Typography>
        </Grid>
      )}
      <Box className={progressLineStartActive} md={0.12} />
      <Box className={progressLineDueActive} md={0.12} />
      <Box className={progressLineDelaytActive} md={0.12} />
      <Grid md={9.5} sx={{ paddingBottom: "10px" }}>
        <Box className="asset-progress__select-button" sx={{ display: "flex" }}>
          {(taskPriority === "High" || taskPriority === "Low") && (
            <img
              src={taskPriority === "High" ? HighLogo : LowLogo}
              alt="hp"
              className="asset-progress__logo-image"
            />
          )}
          <Typography
            sx={{
              textDecoration: "underline",
              paddingLeft: 0.2,
              fontFamily: "GothamMedium !important",
            }}>
            <Link
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              to={curentPageLink}
              state={{ assetDetail: data, brudcrum: breadcrumbsDetail }}
              style={LinkStyle}>
              {data.title}
            </Link>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "-webkit-flex",
            bgcolor: "white",
            width: "100%",
          }}>
          {data.timeline_entries.map((timeLine, index) => {
            let percentage = timeLine?.percentageWidth_overview_page;
            if (percent - data.previous_status_percent < 0) {
              if (data.timeline_entries.length - 2 === index) {
                percentage += percent - data.previous_status_percent;
              }
            }

            return (
              <Box
                sx={{
                  bgcolor: `${timeLine?.color}`,
                  width:
                    index === data.timeline_entries.length - 1
                      ? `${
                          percent - data.previous_status_percent < 0
                            ? 0.1
                            : percent - data.previous_status_percent
                        }%`
                      : `${percentage}%`,
                  height: 8,
                  cursor: "pointer",
                }}
                aria-haspopup="true"
                onMouseEnter={(event) => handleHoverPopoverOpen(event, timeLine)}
                onMouseLeave={handleHoverPopoverClose}
              />
            );
          })}
          <div>
            <Tooltip
              title={
                <Typography fontFamily="GothamBook" fontSize={12}>
                  Represents actual number of business days vs SOP timeline estimated number of
                  business days.
                </Typography>
              }>
              <Typography
                sx={{
                  fontFamily: "GothamMedium",
                  fontSize: "9px",
                  lineHeight: "1.67",
                  letterSpacing: " -0.17px",
                  color: "#262162",
                  width: "4.5rem",
                  paddingLeft: "0.5rem",
                  marginTop: "-3px",
                }}>
                {`(${data.days_till_today}/${data.total_days} Days)`}
              </Typography>
            </Tooltip>
          </div>
        </Box>
      </Grid>

      {/* Hover Popover */}
      <CompleteProgressPopover
        hoveropen={progressAnchor}
        onClose={handleHoverPopoverClose}
        label={popOverTitle}
        duration={popOverDuration}
        coordinates={coordinates}
      />
    </Box>
  );
}

AssetsProgressBarStatus.propTypes = {
  title: PropTypes.string.isRequired,
  barColor: PropTypes.string.isRequired,
  barValue: PropTypes.number.isRequired,
  completedAsset: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  taskPriority: PropTypes.string.isRequired,
  data: PropTypes.objectOf.isRequired,
  breadcrumbsDetail: PropTypes.objectOf.isRequired,
  curentPageLink: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
};

export default AssetsProgressBarStatus;
