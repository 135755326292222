import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
// import React, { useEffect } from "react";
import React from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

// Local imports
// import { API_URL } from "api/endpoints";
import PrivateRoute from "routes/PrivateRoutes";
import Header from "components/Header/Header";
import LoginLanding from "components/Login/LoginLanding";
import Activeassets from "components/Assets/Activeassets";
import Completedassets from "views/CompleteAssets/Completedassets";

import Requestsop from "components/Assets/Requestsops";
import Getstarted from "components/GetStarted/GetStarted";
import PageNotFound from "views/404/Index";
import CompletedAssetsDetail from "views/CompleteAssets/CompletedAssetsDetail/CompletedAssetsDetail";
import CopyLinkPage from "views/CopyLink/CopyLink";
import oidc from "./config";

import("style/App.scss");

const oktaAuth = new OktaAuth(oidc);

function App() {
  const location = useLocation();
  const iscopyLinkPath = location.pathname.includes("/asset/detail/");

  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  const newToken = () => {
    if (JSON.parse(localStorage.getItem("okta-token-storage")) === null) {
      const idToken = null;
      return idToken;
    }
    const { idToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
    return idToken;
  };

  const token = newToken();

  return (
    <div>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {!!token && !iscopyLinkPath && <Header />}
        <Routes>
          <Route path="/" element={<LoginLanding />} />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route element={<PrivateRoute />}>
            <Route path="/get-started" element={<Getstarted />} />
            <Route path="/home" element={<Activeassets />} />
            <Route path="/completed-assets" element={<Completedassets />} />
            <Route path="/completed-assets/assetdetail" element={<CompletedAssetsDetail />} />
            <Route path="/home/assetdetail" element={<CompletedAssetsDetail />} />
            <Route path="/request-sop" element={<Requestsop />} />
            <Route path="/asset/detail/:copyLinkId" element={<CopyLinkPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Security>
    </div>
  );
}

export default App;
