import React, { useState } from "react";
import { Typography, Grid, Menu, MenuItem, Divider, Avatar } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";

import LoadingButton from "@mui/lab/LoadingButton";

function stringAvatar(name) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function AvatarProfile() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { idToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const { name, email } = !!idToken && idToken.claims;

  const open = Boolean(anchorEl);

  const handleSignOut = () => {
    setLoader(true);
    sessionStorage.removeItem("profile");
    localStorage.clear();
    oktaAuth.closeSession();
    oktaAuth.clearStorage();
    oktaAuth.clearStorage();
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut();
  };

  return (
    <Grid container alignItems="center">
      <Grid item md={10} xs={3} sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          onClick={handleClick}
          sx={{ width: 50, height: 50, cursor: "pointer", backgroundColor: "#a27db7" }}
          {...(idToken ? { ...stringAvatar(name) } : "")}
        />
        <Typography
          sx={{
            fontSize: 12,
            color: "#4a4a4a",
            marginLeft: 1.5,
            fontFamily: "GothamMedium",
            fontWeight: 500,
          }}>
          {name}
        </Typography>
      </Grid>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <MenuItem
          sx={{
            display: "block",
            cursor: "default",
            backgroundColor: "white",
          }}
          disableRipple
          className="b-header__avatar-menu-item">
          <Typography
            className="b-header__avatar-menu-item__avatar-signed-in"
            sx={{
              fontSize: "12px",
              fontFamily: "GothamBook",
            }}>
            Signed in as
          </Typography>
          <Typography sx={{ fontFamily: "GothamBook", fontSize: "14px" }}>{email}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            display: "block",
            cursor: "default",
            backgroundColor: "white",
          }}
          disableRipple
          className="b-header__avatar-menu-item">
          <Typography
            sx={{ fontSize: "12px", fontFamily: "GothamBook" }}
            className="b-header__avatar-menu-item__avatar-full-name-head">
            Full name
          </Typography>
          <Typography sx={{ fontFamily: "GothamBook", fontSize: "14px" }}>{name}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem className="b-header__avatar-menu-item-new" onClick={handleSignOut}>
          <button className="b-header__avatar-menu-item-new__btn" type="button">
            <Typography
              sx={{ fontFamily: "GothamBook", fontSize: "14px" }}
              className="b-header__avatar-menu-item-new__avatar-sign-out">
              Sign out
            </Typography>
          </button>

          {loader && <LoadingButton loading />}
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default AvatarProfile;
