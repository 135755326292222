import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import theem from "./BreadCrumbsTheem";

function Breadcrumb({ detail }) {
  const breadcrumbs = [
    <NavLink
      underline="hover"
      to={detail.previousPageBackUrl}
      state={{ pathname: detail.previousPageBackUrl, tabId: 1 }}
      sx={theem.previousPage}>
      {detail.previousPageUrlTitle}
    </NavLink>,
    <Typography key="3" sx={theem.nextPage}>
      {detail.nextPageUrlTitle}
    </Typography>,
  ];
  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="medium" sx={theem.NavigateNextIcon} />}
        aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Box>
  );
}
Breadcrumb.propTypes = {
  detail: PropTypes.objectOf.isRequired,
};

export default Breadcrumb;
