export const completeAssetContainer = {
  compeleteAssets: {
    header: {
      lineHeight: " 0.5",
      letterSpacing: "normal",
      fontFamily: "GothamBlack",
      fontWeight: "900",
      cursor: "pointer",
      color: "#4a4a4a",
      fontSize: "30px",
    },
    text: {
      marginLeft: "10px",
      fontFamily: "GothamMedium",
      fontSize: "20px",
      fontWeight: "500",
      fontsStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#4a4a4a",
    },
  },
  filterButtn: {
    fontFamily: "GothamBook",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: " right",
    color: "#4a4a4a",
  },
  sortButton: {
    button: {
      fontFamily: "GothamBook",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: " normal",
      letterSpacing: " normal",
      textAlign: "right",
      color: " #4a4a4a",
    },
    iccon: {
      marginTop: " -7px",
      width: "1em",
      height: "1em",
      display: "inline-block",
      padding: "3px 0 0px 0px",
      objectFit: "contain",
      color: "#1e70b9",
    },
  },
  allAssetsUniqeSection: {
    text: {
      fontFamily: "GothamBook",
      fontSize: "12px",
      fontWeight: " normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "normal",
      color: "#262262",
      paddingLeft: " 0px",
    },
    detailBtntext: {
      marginLeft: "-6px",
      marginTop: "4px",
      fontFamily: "GothamBook",
      fontSize: "10px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: " 1.2",
      letterSpacing: "-0.08px",
      color: "#216fb4",
    },
    overviewbtnText: {
      marginLeft: "-18px",
      marginTop: "4px",
      fontFamily: "GothamBook",
      fontSize: "10px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: " 1.2",
      letterSpacing: "-0.08px",
      color: "#216fb4",
    },
  },
  daysSection: {
    daysFont: {
      fontFamily: "GothamBook",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.26px",
      textAlign: "center",
      color: "#4a4a4a",
    },
  },
};
