import { Button, Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function LinkButton(props) {
  const {
    title,
    onClick,
    buttonStyle = "c-overview-container__pop-over__view-and-clear-all-btn",
  } = props;
  return (
    <Grid item md={12} mb={3}>
      <Button className={buttonStyle} onClick={onClick}>
        {title}
      </Button>
    </Grid>
  );
}

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  buttonStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

export default LinkButton;
