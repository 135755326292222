/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { requestSOP } from "api/apiMethods";
import { useQuery } from "react-query";

// Local Imports
import Step1 from "images/Pics/Step1.webp";
import Step2 from "images/Pics/Step2.webp";
import Step3 from "images/Pics/Step3.webp";
import Footer from "components/Footer/Footer";
import Heading from "components/HeadingComponent/Heading";
import Description from "components/DescriptionComponent/descriptionComponent";
import parser from "html-react-parser";

const theme = createTheme({
  typography: {
    fontFamily: ["GothamBook"].join(","),
    body1: {
      fontSize: 14,
      lineHeight: 1.57,
      fontWeight: 400,
    },
  },
});

// eslint-disable-next-line consistent-return
const playVid = (vid) => {
  if (vid.controls === false) {
    vid.play();
    // eslint-disable-next-line no-return-assign, no-param-reassign
    setTimeout(() => (vid.controls = "true"), 500);
  } else {
    return null;
  }
};

function Requestsop() {
  const { data, isLoading, isError, error } = useQuery("requestSopData", requestSOP);
  if (isLoading) {
    return (
      <CircularProgress
        size={100}
        sx={{
          color: "#262262",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Container className="c-request-sop" id="sopcont">
        <Heading
          titleStyle="c-request-sop__typography-sop-main"
          variant="h4"
          title={data.data[0]?.title}
        />
        <Heading
          titleStyle="c-request-sop__typography-sub-heading"
          variant="body1"
          title={data.data[0]?.banner_title}
        />

        <Description
          descriptionStyle="c-request-sop__typography-ans"
          variant="body1"
          description={data.data[0]?.short_body}
        />

        <Description
          descriptionStyle="c-request-sop__typography-info-sop"
          variant="body1"
          description={data.data[0]?.body}
        />

        <div className="c-request-sop__border-color" />
        <Grid container pt="59px" sx={{ justifyContent: "center " }}>
          <Grid item md={1.85} pl={5.875}>
            <Grid container sx={{ display: "block" }}>
              <Grid item md={4} sx={{ marginTop: "-13px !important" }}>
                <img className="c-request-sop__imgstyle-step-one" src={Step1} alt="new File" />
              </Grid>
              <Grid item md={4} sx={{ paddingTop: "57px" }}>
                <div>
                  <img
                    className="c-request-sop__imagestyle-step-two"
                    src={Step2}
                    alt="Custom File"
                  />
                </div>
              </Grid>
              <Grid item md={4} sx={{ paddingTop: "53px" }}>
                <div>
                  <img
                    className="c-request-sop__image-style-four"
                    src={Step3}
                    alt="New Task File"
                  />
                </div>
              </Grid>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                width: "100%",
                height: "300px",
                borderRight: "dashed",
                color: "#5eaee0",
                marginTop: "-339px",
                marginLeft: "-47px",
              }}
            />
          </Grid>
          <Grid item md={10} pl={5.875}>
            <Grid container sx={{ display: "block" }} spacing={2}>
              <Grid item md={11} sx={{ paddingTop: "29px" }}>
                <Heading
                  titleStyle="c-request-sop__typography-read-the-mock-up"
                  variant="body1"
                  title={data.data[0]?.top_title}
                />
                <Description
                  descriptionStyle="c-request-sop__typography-we-ask-that-you-log"
                  variant="body1"
                  description={parser(data.data[0]?.top_body)}
                />
              </Grid>

              <Grid item md={11}>
                <Grid container>
                  <Grid item md={5.5} sx={{ paddingTop: "32px" }}>
                    <Heading
                      titleStyle="c-request-sop__typography-read-the-mock-up"
                      variant="body1"
                      title={data.data[0]?.mid_title}
                    />

                    <Description
                      descriptionStyle="c-request-sop__typography-we-ask-that-you-log"
                      variant="body1"
                      description={parser(data.data[0]?.mid_body)}
                    />
                  </Grid>
                  <Grid item md={0.5} />
                  <Grid item md={6} sx={{ paddingTop: "30px", height: 160, width: 350 }}>
                    <video
                      height="160"
                      onClick={(e) => playVid(e.target)}
                      poster={data.data[0]?.video_poster}
                      preload="auto"
                      src={data.data[0]?.video_source}>
                      Your browser does not support the video tag.
                    </video>
                    {/* {parser(data.data[0]?.section_video)} */}
                    {/* <span dangerouslySetInnerHTML={{ __html: data.data[0]?.section_video }} /> */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={11}>
                <Heading
                  titleStyle="c-request-sop__typography-read-the-mock-up"
                  variant="body1"
                  title={data.data[0]?.bottom_title}
                />

                <Description
                  descriptionStyle="c-request-sop__typography-we-ask-that-you-log"
                  variant="body1"
                  description={parser(data.data[0]?.bottom_body)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="c-request-sop__bottom-line" />
        <Footer status />
      </Container>
    </ThemeProvider>
  );
}

export default Requestsop;
