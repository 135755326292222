import React from "react";
import PropTypes from "prop-types";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid, Tooltip, Typography } from "@mui/material";

function ActiveAssetsFilterMenu(props) {
  const { dotColor, title, tooltip } = props;
  return (
    <Grid item className="detail-view-container__grid-col" sx={{ marginTop: 1.5 }}>
      <CircleIcon
        sx={{ color: dotColor, width: 10, height: 10, marginRight: "4px", marginTop: -0.3 }}
      />
      <Tooltip title={tooltip}>
        <Typography
          sx={{
            lineHeight: 0.5,
            paddingRight: 0,
            color: "#4a4a4a",
            fontSize: 14,
            fontFamily: "GothamBook",
          }}
          pr={2.5}>
          {title}
        </Typography>
      </Tooltip>
    </Grid>
  );
}

ActiveAssetsFilterMenu.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  dotColor: PropTypes.string.isRequired,
};

export default ActiveAssetsFilterMenu;
