import React from "react";
import { Typography, Grid, Divider, Box } from "@mui/material";
import PropTypes from "prop-types";
import CircleIcon from "@mui/icons-material/Circle";

import Heading from "components/HeadingComponent/Heading";
import RequestOverviewData from "components/RequestOverviewData/RequestOverviewData";

function CopyLinkDetail({ asset }) {
  const os = navigator.userAgent;
  let finalOs;
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  }

  return (
    <Grid container sx={{ marginTop: "52px" }}>
      <Grid item xs={6} pr={2}>
        <Grid container id="downloadcontent" ml={2} className="dwcontent">
          {asset.reference_entities.length === 0 && (
            <>
              <Grid item md={1.5} mt="-3px" mr="16px">
                <Typography
                  sx={{
                    fontFamily: "GothamBold",
                    color: "#4a4a4a",
                    fontSize: "12px",
                    cursor: "pointer",
                    paddingTop: "1px",
                    lineHeight: "1.5",
                    letterSpacing: " 0.00938em",
                  }}>
                  {asset.updated_date}
                </Typography>
              </Grid>

              <Grid item md={0.38}>
                <CircleIcon
                  className="mycircle"
                  sx={{
                    fontSize: "13px",
                    color: `${asset.color}`,
                    zIndex: 1,
                  }}
                />
              </Grid>

              <Grid item md={9} mt="-2px" ml="16px">
                <Typography
                  sx={{
                    fontFamily: "GothamMedium",
                    fontSize: "14px",
                    paddingTop: "1px",
                    color: "#262262",
                    letterSpacing: "-0.013em ",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}>
                  {asset.custom_status_label}{" "}
                  <span className="b-asset-detail-view-container__body-assets__asset-day">
                    ({asset.days_till_today}d)
                  </span>
                </Typography>
              </Grid>

              <Grid item md={2} />

              <Grid item className="mycontainer" md={0.18} />

              <Grid item md={9} ml={1.5} mt="2px" pb={2}>
                <Typography
                  sx={{
                    fontFamily: "GothamBookItalic",
                    fontSize: "12px ",
                    paddingLeft: "6px",
                  }}
                  className="b-asset-detail-view-container__body-assets__asset-status-description">
                  {asset.status_description}
                </Typography>
              </Grid>
            </>
          )}
          {asset.reference_entities != null &&
            asset.reference_entities.map((childAsset, index) => (
              <Grid container spacing={1}>
                <Grid item xs={2.1}>
                  <Typography
                    sx={{
                      fontFamily: "GothamBold",
                      color: " #4a4a4a",
                      fontSize: "12px",
                      // cursor: "pointer",
                      paddingTop: "1px",
                      lineHeight: "1.5",
                      letterSpacing: " 0.00938em",
                    }}>
                    {childAsset?.updated_date}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <CircleIcon
                    className="mycircle"
                    sx={{
                      fontSize: "13px",
                      color: `${childAsset.color}`,
                      zIndex: 1,
                    }}
                  />
                </Grid>
                <Grid item xs={8.9}>
                  <Typography
                    sx={
                      index === 0
                        ? {
                            fontFamily: "GothamBold",
                            fontSize: "16px",
                            paddingTop: "1px",
                            color: "#262262",
                            letterSpacing: "0.00938em ",
                            lineHeight: "16px",
                            fontWeight: "bold",
                          }
                        : {
                            fontFamily: "GothamMedium",
                            fontSize: "14px",
                            paddingTop: "1px",
                            color: "#262262",
                            letterSpacing: "-0.013em ",
                            lineHeight: "16px",
                            fontWeight: "500",
                          }
                    }>
                    {childAsset?.custom_status_label}{" "}
                    <span className="b-asset-detail-view-container__body-assets__asset-day">
                      ({childAsset?.days}d)
                    </span>
                  </Typography>
                </Grid>

                <Box sx={finalOs === "Windows" ? { width: "20.2%" } : { width: "19.8%" }} />

                {index + 1 !== asset?.reference_entities.length ? (
                  <Box
                    className="b-asset-detail-view-container__body-assets__indicator-divider"
                    sx={{
                      marginBottom: "-3px",
                      marginTop:
                        index === 0 && childAsset?.custom_status_label.length > 32
                          ? "-20px"
                          : "-7px",
                    }}
                  />
                ) : null}
                <Grid
                  item
                  xs={7.2}
                  sx={finalOs === "Windows" ? { marginLeft: "4%" } : { marginLeft: "5%" }}
                  className={
                    index + 1 === asset?.reference_entities.length &&
                    "b-asset-detail-view-container__last-description"
                  }>
                  <Typography
                    sx={{
                      fontFamily: "GothamBookItalic",
                      fontSize: "12px ",
                      marginTop: "-9px",
                      marginBottom: "20px",
                    }}
                    className="b-asset-detail-view-container__body-assets__asset-status-description">
                    {childAsset?.status_description}
                  </Typography>
                </Grid>
                <Grid xs={1.5} />
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Divider orientation="vertical" flexItem sx={{ border: "solid 1px #d5cccc" }} />

      <Grid item xs={4.5}>
        <Grid container ml={5}>
          <Grid xs={1} />
          <Grid xs={11}>
            <Grid item md={12} pb={1.25}>
              <Heading
                title="Asset Overview"
                variant="body"
                titleStyle="b-asset-detail-view-container__asset-over-view-section__asset-over-view-heading"
              />
            </Grid>

            <RequestOverviewData label="Type" value={asset.type == null ? "---" : asset.type} />
            <RequestOverviewData label="Format" value={asset?.format} />

            <Grid item md={12} pb={1.25} pt={3.25}>
              <Heading
                title="Request Overview"
                variant="body"
                titleStyle="b-asset-detail-view-container__asset-over-view-section__asset-over-view-heading"
              />
            </Grid>

            <RequestOverviewData label="Requester" value={asset?.requester} />
            <RequestOverviewData label="Affiliation" value={asset?.affiliation} />
            <RequestOverviewData label="Partner" value={asset?.partner} space={2.5} />

            <Divider sx={{ width: "100%", height: "1px", marginBottom: 2.39 }} />

            <RequestOverviewData label="Request Type" value={asset?.request_type} />
            <RequestOverviewData label="Request ID" value={asset?.wrike_task_id} space={2.5} />

            <Divider sx={{ width: "100%", height: "1px", marginBottom: 2.39 }} />

            <RequestOverviewData label="Wondros Contact" value={asset?.wondros_contact} />
            <RequestOverviewData label="Contact Email" value={asset?.contact_email} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
CopyLinkDetail.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default CopyLinkDetail;
