import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function Description({ description, descriptionStyle = "", variant = " " }) {
  return (
    <Typography variant={variant} className={descriptionStyle}>
      {description}
    </Typography>
  );
}
Description.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string]).isRequired,
  descriptionStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
};
export default Description;
