import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Box, Typography, CircularProgress, Grid } from "@mui/material";
import CopyLinkDetail from "components/CompeleteAssets/CompletedAssetDetail/CopyLinkDetail";
import { getCopyLinkDetail } from "api/apiMethods";

function CopyLink() {
  const [assetDetail, setAssetDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params?.copyLinkId) {
      setLoading(true);
      getCopyLinkDetail(params?.copyLinkId)
        .then((responce) => {
          if (responce.status === 200) {
            setAssetDetail(responce.data);
          } else {
            navigate("/notfound");
          }
          setLoading(false);
        })
        .catch(() => {
          navigate("/notfound");
        });
    } else {
      navigate("/notfound");
    }
  }, []);

  return (
    <>
      {loading && (
        <CircularProgress
          size={100}
          sx={{
            color: "#262262",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}

      {!loading && (
        <Container
          className="b-container-completedAssetDetails"
          sx={{ margin: "auto", width: "72.30%" }}>
          <Box className="b-container-completedAssetDetails__header">
            <Typography
              sx={{
                lineHeight: " 0.5",
                letterSpacing: "normal",
                fontFamily: "GothamBlack",
                fontWeight: "900",
                color: "#4a4a4a",
                fontSize: "30px",
              }}>
              Asset Tracker Status Detail
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: "30px",
            }}>
            <Box
              sx={{
                fontFamily: "GothamBook",
                fontSize: "20px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: " -0.17px",
                color: "#262262",
              }}>
              {assetDetail[0]?.title}
            </Box>
          </Box>

          {assetDetail[0] && <CopyLinkDetail asset={assetDetail[0]} indicatorMarginLeft="0" />}

          <Grid sx={{ paddingBottom: 2, marginTop: 4 }} />
        </Container>
      )}
    </>
  );
}

export default CopyLink;
