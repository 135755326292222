import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import React from "react";
import PropTypes from "prop-types";

function FormControlInput(props) {
  const {
    mainContainerStyle,
    label,
    // checkBoxStyle,
    labelStyle,
    // status,
    onChange,
    value,
    checked,
    gridVal,
  } = props;
  return (
    <Grid item md={gridVal} mb={1.5}>
      <FormGroup>
        <FormControlLabel
          checked={checked}
          className={mainContainerStyle}
          classes={{
            label: labelStyle,
          }}
          control={
            <Checkbox
              icon={
                <CheckBoxOutlineBlankSharpIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#216FB4",
                  }}
                />
              }
              checkedIcon={
                <CheckBoxSharpIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#216FB4",
                  }}
                />
              }
            />
          }
          label={label}
          onChange={onChange}
          value={value}
        />
      </FormGroup>
      {/* <Box pl={2.98}>
        <FormGroup>
          {status?.map((subStatus) => (
            <FormControlLabel
              classes={{
                label: "checklabels",
              }}
              sx={{ pt: 0.8, pl: 2.75 }}
              control={
                <Checkbox
                  onChange={(e) => toggleSelectionCheckbox(e, subStatus)}
                  checked={selectedStatus.includes(subStatus.wrike_status_id)}
                  value={subStatus.wrike_status_id}
                  icon={
                    <CheckBoxOutlineBlankSharpIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                        color: "#216FB4",
                      }}
                    />
                  }
                  checkedIcon={
                    <DoneSharpIcon
                      sx={{
                        margin: "2px",
                        width: "12px",
                        height: "12px",
                        color: "#216FB4",
                        boxShadow: "0px 0px 0px 1px #216FB4 inset",
                      }}
                    />
                  }
                />
              }
              label={subStatus.name}
            />
          ))}
        </FormGroup>
      </Box> */}
    </Grid>
  );
}

FormControlInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string]).isRequired,
  mainContainerStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
  // checkBoxStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
  labelStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
  // status: PropTypes.arrayOf.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.func.isRequired,
  gridVal: PropTypes.number.isRequired,
};

export default FormControlInput;
