import {
  Typography,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  Grid,
  MenuItem,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RadioButtonCheckedRounded, RadioButtonUncheckedRounded } from "@mui/icons-material";

function DropdownOptionsSort(props) {
  const {
    data,
    selectedSortValues,
    setSelectedSortValues,
    setFilterNames,
    // handleClickSort,
    isOpenSort,
    // setIsOpenSort,
  } = props;

  const os = navigator.userAgent;
  let system = "window";
  if (os.search("Mac") !== -1) {
    system = "mac";
  }

  const toggleSelectionCheckbox = (event) => {
    if (event.currentTarget.checked) {
      // setIsOpenSort(false);
      setSelectedSortValues(() => {
        return [event.target.value];
      });
      // setIsOpenSort((prevState) => !prevState);
      setFilterNames((prevState) => {
        return prevState.filter(
          (a) =>
            a !== "Status" &&
            a !== "Newest Requests" &&
            a !== "Oldest Requests" &&
            a !== "High Priority" &&
            a !== "Low Priority"
        );
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
    } else {
      // setIsOpenSort(false);
      setSelectedSortValues(() => {
        return [];
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  return (
    <FormControl variant="standard" sx={isOpenSort ? { width: 95 } : { width: 65 }}>
      <Typography
        sx={{
          margin: "0px",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.00938em",
          fontSize: "14px",
          cursor: "pointer",
          fontFamily: "GothamBook",
          color: "#4a4a4a",
          // marginLeft: marginLeftProps,
          display: "flex",
          paddingLeft: isOpenSort && "15px",
          // zIndex: 2,
        }}
        id="demo-simple-select-standard-label">
        SORT
      </Typography>
      <Select
        labelId="demo-simple-select-standard-label"
        label="SORT"
        id="demo-simple-select-standard"
        defaultValue="SORT"
        // onOpen={handleClickSort}
        // onClose={handleClickSort}
        onChange={toggleSelectionCheckbox}
        className={isOpenSort && "p-request-pop-over__request-type-box__request-container-head"}
        sx={{
          color: "white",
          // ".MuiOutlinedInput-notchedOutline": {
          //   borderColor: "red",
          // },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "red",
          // },
          // "&:hover .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "red",
          // },
          ".MuiSvgIcon-root ": {
            fill: "#216fb4 !important",
            width: isOpenSort ? 30 : 25,
          },
          "&.Mui-focused .MuiSelect-select": {
            background: "#ffffff00",
          },
        }}
        MenuProps={{
          transitionDuration: 0,
          classes: { paper: "reqtypePaper" },
          PaperProps: {
            sx: {
              "& .MuiList-root": {
                paddingTop: 0,
                background: "rgba(250, 244, 244, 0) !important",
              },
              "& .MuiMenuItem-root": {
                paddingTop: 0,
                background: "rgba(250, 244, 244, 0) !important",
              },
              "&:hover .MuiMenuItem-root": {
                paddingTop: 0,
                background: "rgba(250, 244, 244, 0) !important",
              },
              "& .Mui-focusVisible": {
                paddingTop: 0,
                background: "transparent !important",
                transition: "transparent 0ms  !important",
                transitionDuration: 0,
              },
              // width: 500,
              position: "absolute",
              left: system === "mac" ? "71.1% !important" : "67.5% !important",
            },
          },
        }}
        IconComponent={ExpandMoreIcon}
        style={
          isOpenSort
            ? { position: "absolute", top: -7, left: "0px", width: "82px" }
            : { position: "absolute", top: -7, left: "0px", width: "62px" }
        }
        disableUnderline
        variant="standard">
        <MenuItem value="" selected>
          <Grid container className="p-sort-popover__sort-popover-box__sort-popover-conatiner">
            {data.map((item) => {
              return (
                <RadioGroup
                  name="radio-buttons-group"
                  onChange={toggleSelectionCheckbox}
                  style={{
                    background: "white",
                    width: 200,
                    // filter: "drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.15)) ",
                  }}>
                  <FormGroup>
                    <FormControlLabel
                      classes={{
                        label: "p-sort-popover__sort-popover-box__sort-popover-text",
                      }}
                      sx={{
                        // pt: 2.95,
                        pl: 2.75,
                        mt: 1.5,
                      }}
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedRounded
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#216FB4",
                              }}
                            />
                          }
                          checkedIcon={
                            <RadioButtonCheckedRounded
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#216FB4",
                              }}
                            />
                          }
                        />
                      }
                      checked={
                        item === "Newest Requests" && selectedSortValues.length === 0
                          ? true
                          : selectedSortValues.includes(item)
                      }
                      label={item}
                      value={item}
                      name={item}
                    />
                  </FormGroup>
                </RadioGroup>
              );
            })}
          </Grid>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

DropdownOptionsSort.propTypes = {
  selectedSortValues: PropTypes.arrayOf.isRequired,
  data: PropTypes.arrayOf.isRequired,
  setSelectedSortValues: PropTypes.func.isRequired,
  setFilterNames: PropTypes.func.isRequired,
  // handleClickSort: PropTypes.func.isRequired,
  // setIsOpenSort: PropTypes.func.isRequired,
  isOpenSort: PropTypes.bool.isRequired,
};

export default DropdownOptionsSort;
