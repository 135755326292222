import React from "react";
import { AppBar, Toolbar, Typography, Button, Grid, List } from "@mui/material";
import { NavLink } from "react-router-dom";

import AssetTrackerLogo from "images/logos/AssetTrackerLogo.webp";
import MenubarItem from "components/MenubarItem/MenubarItem";
import AvatarProfile from "./avatar/Avatar";

function Header() {
  return (
    <AppBar className="app-bar-container" sx={{ backgroundColor: "#ffffff" }}>
      <Toolbar sx={{ backgroundColor: "#ffffff" }} mx={5}>
        <Grid mx={17} container>
          <Grid item md={8.5}>
            <Grid container>
              <Grid item md={3}>
                <NavLink to="/home">
                  <img
                    src={AssetTrackerLogo}
                    alt="hp"
                    className="AssetTrackerLogo"
                    style={{
                      width: "120px",
                      height: "50px",
                      cursor: "pointer",
                      paddingTop: 4,
                    }}
                  />
                </NavLink>
              </Grid>

              <Grid item md={9} className="app-bar-container__menu-list-container">
                <List className="app-bar-container__menu-list">
                  <MenubarItem title="GET STARTED" link="/get-started" />
                  <MenubarItem title="ACTIVE ASSETS" link="/home" />
                  <MenubarItem title="COMPLETED ASSETS" link="/completed-assets" />
                  <MenubarItem title="REQUESTS SOP" link="/request-sop" />
                </List>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={3.5} sx={{ display: "flex", alignItems: "center" }}>
            <Grid container ml={5} alignItems="center">
              <Grid item md={6}>
                <Button
                  id="commsButton"
                  variant="outlined"
                  href="https://allofuscomms.org/"
                  target="_blank"
                  sx={{
                    width: "113px",
                    height: "34px",
                    fontSize: "9px",
                    color: "#4a4a4a",
                    border: "solid 1px #216fb4",
                    borderRadius: "99px",
                    textTransform: "capitalize",
                    padding: "9px 0px 9px 0px",
                  }}>
                  <Typography
                    sx={{
                      fontSize: "12px ",
                      textTransform: "capitalize",
                      //   marginTop: "-3px",
                      fontWeight: "500 ",
                      fontFamily: "GothamMedium ",
                      color: "#4a4aa",
                    }}>
                    Comms Portal
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={6}>
                <AvatarProfile />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
