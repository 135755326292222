import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types, no-unused-vars
function GetStartedFAQs({ description, image }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box
        className="faq-header"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: 5,
        }}>
        FAQs
        <img
          src="https://assettracker.s3.us-east-2.amazonaws.com/media/at_faq_icon1.png"
          height={60}
          width={60}
          alt="question mark"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: 0,
          justifyContent: "center",
        }}>
        {description}
      </Box>
    </Box>
  );
}
GetStartedFAQs.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string]).isRequired,
  //   image: PropTypes.oneOfType([PropTypes.string]).isRequired,
};
export default GetStartedFAQs;
