import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

import "style/components/RequestOverviewData/requestoverviewdata.scss";

function RequestOverviewData({ label, value, space = 0 }) {
  return (
    // <>
    <Box className="b-request-over-view-data__request-box-main" mb={space}>
      <Grid item md={5.1}>
        <Typography variant="body" className="b-request-over-view-data__request-label">
          {label}:
        </Typography>
      </Grid>
      <Grid item md={6.9}>
        <Typography variant="body" className="b-request-over-view-data__request-label-detail">
          {value}
        </Typography>
      </Grid>
    </Box>
    // </>
  );
}

RequestOverviewData.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string]).isRequired,
  space: PropTypes.oneOfType([PropTypes.number]).isRequired,
};

export default RequestOverviewData;
