import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";

function PrivateRoute() {
  const location = useLocation();

  const newToken = () => {
    if (JSON.parse(localStorage.getItem("okta-token-storage")) === null) {
      const idToken = null;
      return idToken;
    }
    const { idToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
    return idToken;
  };

  const token = newToken();

  if (token === undefined || token === null) {
    return <Navigate to={{ pathname: "/", state: { from: location } }} replace />;
  }

  return <Outlet />;
}

export default PrivateRoute;
