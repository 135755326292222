import {
  Typography,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  Box,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";

function DropdownOptionsCheckbox(props) {
  const {
    checkBoxData,
    selectedStatus,
    setSelectedStatus,
    filterNames,
    setFilterNames,
    setFilterColumnList,
    filterColumnList,
    isOpenStatus,
    handleClickStatus,
  } = props;

  const toggleSelectionCheckbox = (event, status) => {
    const FilternamesList = [...filterNames];
    const { value, checked } = event.target;

    if (checked) {
      FilternamesList.push(status);
      setFilterNames(FilternamesList);
      setSelectedStatus((prevState) => {
        return [...prevState, value];
      });
    } else {
      setFilterNames((prev) => {
        return prev.filter((state) => state.wrike_status_id !== value);
      });
      setSelectedStatus((prevState) => {
        return prevState.filter((a) => a !== value);
      });
    }
  };

  const userRoles = localStorage.getItem("user_roles");

  const isUserRolesPartner = userRoles.includes("partner");

  // const os = navigator.userAgent;
  // let system = "window";
  // if (os.search("Mac") !== -1) {
  //   system = "mac";
  //   console.log(system);
  // }

  const handleAllChildCheckboxes = (event) => {
    const selectedStatuss = checkBoxData.mainStatus.filter((a) => a.name === event.target.value);
    const selectedData = selectedStatuss[0].status.map((selected) => selected);
    const FilternamesList = [...filterNames];
    if (event.target.checked) {
      const selectedValues = selectedStatuss[0].status.map((selected) => {
        return selected.wrike_status_id;
      });
      selectedData.forEach((item) => {
        const existItem = FilternamesList.find(
          (data) => data.wrike_status_id === item.wrike_status_id
        );
        if (!existItem) {
          FilternamesList.push(item);
        }
      });
      setFilterNames(FilternamesList);
      setSelectedStatus((prevState) => {
        return [...prevState, ...selectedValues];
      });
      setFilterColumnList([...filterColumnList, selectedStatuss[0]]);
    } else {
      const unSelectedValues = selectedData.map((selected) => {
        return selected.wrike_status_id;
      });
      const updateListArray = filterColumnList.filter(
        (item) => item.name !== selectedStatuss[0].name
      );

      const filterListName = FilternamesList.filter((item) => {
        const isexistItem = selectedData.find(
          (items) => items.wrike_status_id === item.wrike_status_id
        );
        if (isexistItem) {
          return item !== isexistItem;
        }
        return item;
      });
      setFilterNames(filterListName);
      setFilterColumnList(updateListArray);
      setSelectedStatus((prevState) => {
        return prevState.filter((a) => !unSelectedValues.includes(a));
      });
    }
  };

  const handleClearAll = () => {
    const filterNameList = [...filterNames];
    const filterListName = filterNameList.filter((item) => {
      const isexistItem = selectedStatus.find((items) => items === item.wrike_status_id);
      if (isexistItem) {
        return item.wrike_status_id !== isexistItem;
      }
      return item;
    });
    setFilterNames(filterListName);
    setSelectedStatus([]);
    setFilterColumnList([]);

    sessionStorage.removeItem("selectedStatusDetail");
  };
  return (
    <FormControl variant="standard" sx={isOpenStatus ? { width: 85 } : { width: 82 }}>
      <Typography
        sx={{
          margin: "0px",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.00938em",
          fontSize: "14px",
          cursor: "pointer",
          fontFamily: "GothamBook",
          color: "#4a4a4a",
          // marginLeft: marginLeftProps,
          display: "flex",
          paddingLeft: isOpenStatus && "15px",
          // zIndex: 2,
        }}
        id="demo-simple-select-standard-label">
        STATUS
      </Typography>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        // classes={{ : "reqtypePaper" }}
        onOpen={handleClickStatus}
        onClose={handleClickStatus}
        sx={{
          // bgcolor: "red",
          // color: "white",
          // ".MuiOutlinedInput-notchedOutline": {
          //   borderColor: "rgba(228, 219, 233, 0.25)",
          // },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "rgba(228, 219, 233, 0.25)",
          // },
          " .MuiPaper-root": {
            borderColor: "rgba(228, 219, 233, 0.25)",
            background: "red !important",
            width: "500px",
          },
          ".MuiSvgIcon-root ": {
            fill: "#216fb4 !important",
            width: isOpenStatus ? 30 : 25,
          },
          ".MuiSelect-select": {
            background: "#ffffff00",
          },
        }}
        className={isOpenStatus && "p-request-pop-over__request-type-box__request-container-head"}
        MenuProps={{
          classes: { paper: "reqtypePaper" },
          PaperProps: {
            sx: {
              // bgcolor: "pink",
              "& .MuiList-root": {
                paddingTop: 0,
                // background: "red !important",
                // boxShadow: "none !important",
              },
              // width: 500,
              position: "absolute",
              // left: system === "mac" ? "42.5% !important" : "42% !important",
            },
          },
        }}
        // className="p-status-popover__status-popover-box__status-popover-main-container"
        IconComponent={ExpandMoreIcon}
        style={
          isOpenStatus
            ? { position: "absolute", top: -7, left: "0px", width: "100px" }
            : { position: "absolute", top: -7, left: "0px", width: "82px" }
        }
        disableUnderline
        value={selectedStatus}
        renderValue={() => {}}
        variant="standard">
        <Box className="p-status-popover__status-popover-box" sx={{ display: "-webkit-flex" }}>
          {/* <Box className="p-status-popover__status-popover-box__status-popover-box-main"> */}
          <Grid
            className="p-status-popover__status-popover-box__status-popover-conatiner"
            container>
            <Grid item md={10} />

            <Grid item md={1.95} pt={1}>
              <Button
                sx={{
                  padding: "0px 0px 0px 0px",
                  borderRadius: "0px",
                  marginLeft: "16px",
                  fontSize: "14px",
                  fontFamily: "GothamBook",
                  color: " #216fb4",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
                onClick={handleClearAll}>
                Clear All
              </Button>
            </Grid>

            <Grid item md={0.2} />

            <Grid
              container
              className="p-status-popover__status-popover-box__status-popover-main-box"
              sx={
                isUserRolesPartner
                  ? { height: "auto", marginBottom: 2 }
                  : { height: "300px", overflow: "auto" }
              }>
              <Grid item md={6} mb={3}>
                {checkBoxData !== null &&
                  checkBoxData.mainStatus
                    .filter((a) => a.name !== "Completed/Delivered" && a.name !== "Under Approval")
                    .map((status) => {
                      return (
                        <>
                          <FormGroup>
                            <FormControlLabel
                              classes={{
                                label:
                                  "p-status-popover__status-popover-box__status-popover-main-heading",
                              }}
                              sx={{ pt: 0.8, pl: 2.75 }}
                              control={
                                <Checkbox
                                  checked={status.status.every((item) => {
                                    return selectedStatus.some(
                                      (value) => value === item.wrike_status_id
                                    );
                                  })}
                                  icon={
                                    <CheckBoxOutlineBlankSharpIcon
                                      sx={{
                                        width: "16px",
                                        height: "16px",
                                        color: "#216FB4",
                                      }}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxSharpIcon
                                      sx={{
                                        width: "16px",
                                        height: "16px",
                                        color: "#216FB4",
                                      }}
                                    />
                                  }
                                  onChange={handleAllChildCheckboxes}
                                  value={status.name}
                                />
                              }
                              label={
                                status.name === "Intake" ? `Request ${status.name}` : status.name
                              }
                            />
                          </FormGroup>

                          {!isUserRolesPartner && (
                            <Box pl={2.98}>
                              <FormGroup>
                                {status.status.map((subStatus) => (
                                  <FormControlLabel
                                    classes={{
                                      label:
                                        "p-status-popover__status-popover-box__status-popover-text",
                                    }}
                                    sx={{ pt: 0.8, pl: 2.75 }}
                                    control={
                                      <Checkbox
                                        onChange={(e) => toggleSelectionCheckbox(e, subStatus)}
                                        checked={selectedStatus.includes(subStatus.wrike_status_id)}
                                        value={subStatus.wrike_status_id}
                                        icon={
                                          <CheckBoxOutlineBlankSharpIcon
                                            sx={{
                                              width: "16px",
                                              height: "16px",
                                              color: "#216FB4",
                                            }}
                                          />
                                        }
                                        checkedIcon={
                                          <DoneSharpIcon
                                            sx={{
                                              margin: "2px",
                                              width: "12px",
                                              height: "12px",
                                              color: "#216FB4",
                                              boxShadow: "0px 0px 0px 1px #216FB4 inset",
                                              // border: "0.3px solid",
                                              // borderColor: "#white",
                                            }}
                                          />
                                        }
                                      />
                                    }
                                    label={subStatus.name}
                                  />
                                ))}
                              </FormGroup>
                            </Box>
                          )}
                        </>
                      );
                    })}
              </Grid>
              <Grid item md={0.2} />
              <Grid item md={5} mb={3}>
                {checkBoxData !== null &&
                  checkBoxData.mainStatus
                    .filter((a) => a.name === "Under Approval")
                    .map((status) => (
                      <>
                        <FormGroup>
                          <FormControlLabel
                            classes={{
                              label:
                                "p-status-popover__status-popover-box__status-popover-main-heading",
                            }}
                            sx={{ pt: 0.8, pl: 2.75 }}
                            control={
                              <Checkbox
                                checked={status.status.every((item) => {
                                  return selectedStatus.some(
                                    (value) => value === item.wrike_status_id
                                  );
                                })}
                                icon={
                                  <CheckBoxOutlineBlankSharpIcon
                                    sx={{
                                      width: "16px",
                                      height: "16px",
                                      color: "#216FB4",
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <CheckBoxSharpIcon
                                    sx={{
                                      width: "16px",
                                      height: "16px",
                                      color: "#216FB4",
                                    }}
                                  />
                                }
                                onChange={handleAllChildCheckboxes}
                                value={status.name}
                              />
                            }
                            label={`Request ${status.name}`}
                          />
                        </FormGroup>
                        {!isUserRolesPartner && (
                          <Box pl={2.98}>
                            <FormGroup>
                              {status.status.map((subStatus) => (
                                <FormControlLabel
                                  classes={{
                                    label:
                                      "p-status-popover__status-popover-box__status-popover-text",
                                  }}
                                  sx={{ pt: 0.8, pl: 2.75 }}
                                  control={
                                    <Checkbox
                                      onChange={(e) => toggleSelectionCheckbox(e, subStatus)}
                                      checked={selectedStatus.includes(subStatus.wrike_status_id)}
                                      value={subStatus.wrike_status_id}
                                      icon={
                                        <CheckBoxOutlineBlankSharpIcon
                                          sx={{
                                            width: "16px",
                                            height: "16px",
                                            color: "#216FB4",
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <DoneSharpIcon
                                          sx={{
                                            margin: "2px",
                                            width: "12px",
                                            height: "12px",
                                            color: "#216FB4",
                                            boxShadow: "0px 0px 0px 1px #216FB4 inset",
                                            // border: "0.3px solid",
                                            // borderColor: "#white",
                                          }}
                                        />
                                      }
                                    />
                                  }
                                  label={subStatus.name}
                                />
                              ))}
                            </FormGroup>
                          </Box>
                        )}
                      </>
                    ))}
              </Grid>
            </Grid>
          </Grid>
          {/* </Box> */}
        </Box>
        {/* ); */}
        {/* })} */}
      </Select>
    </FormControl>
  );
}

DropdownOptionsCheckbox.propTypes = {
  checkBoxData: PropTypes.arrayOf.isRequired,
  filterColumnList: PropTypes.arrayOf.isRequired,
  setFilterColumnList: PropTypes.func.isRequired,
  setFilterNames: PropTypes.func.isRequired,
  filterNames: PropTypes.arrayOf.isRequired,
  selectedStatus: PropTypes.arrayOf.isRequired,
  setSelectedStatus: PropTypes.func.isRequired,
  handleClickStatus: PropTypes.func.isRequired,
  isOpenStatus: PropTypes.bool.isRequired,
};

export default DropdownOptionsCheckbox;
