import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Box, Typography, Stack, Grid, Divider } from "@mui/material";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// Local Imports
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import CopyLinkPopOver from "components/CompeleteAssets/CompletedAssetDetail/CopyLinkPopOver";
import CopyLinkDetail from "components/CompeleteAssets/CompletedAssetDetail/CopyLinkDetail";
import AssetDetailPdf from "components/PdfGenerator/PdfScreen/AssetDetailPdf";
import Footer from "components/Footer/Footer";

function CompeletedAssetsDetail() {
  const [assetDetail, setAssetDetail] = useState(null);
  const [openCopyPopup, setOpenCopyPopup] = useState(false);
  const [anchorCopy, setAnchorCopy] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setAssetDetail(location.state.assetDetail);
    }
  }, []);

  const handleClickpop = (event) => {
    setAnchorCopy(event.currentTarget);
    setOpenCopyPopup(true);
    setTimeout(() => {
      setOpenCopyPopup(false);
    }, 1500);
    const copyLInk = `${process.env.REACT_APP_DOMAIN_URL}/asset/detail/${assetDetail.task_id}`;
    navigator.clipboard.writeText(copyLInk);
  };

  const printDocument = () => {
    const os = navigator.userAgent;

    document.getElementById("pdfParentContainer").style.display = "block";

    html2canvas(document.getElementById("pdfContainerId"), {}).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      let pdf = jsPDF("p", "in", "a3");
      if (os.search("Mac") !== -1) {
        pdf = jsPDF("p", "pt", "a1", true);
      }
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("Asset_Detail.pdf");
    });

    document.getElementById("pdfParentContainer").style.display = "none";
  };

  const brudcrumDetail = {
    previousPageUrlTitle: location.state.brudcrum?.previousPageUrlTitle,
    previousPageBackUrl: location.state.brudcrum?.previousPageBackUrl,
    nextPageUrlTitle: location.state.brudcrum?.nextPageUrlTitle,
    nexPagetUrl: location.state.brudcrum?.nexPagetUrl,
  };

  return (
    <>
      {assetDetail !== null && (
        <AssetDetailPdf
          assetDetail={assetDetail}
          pdfContainerId="pdfContainerId"
          pdfParentContainer="pdfParentContainer"
        />
      )}
      <Container
        className="b-container-completedAssetDetails pdfGenerator "
        sx={{ margin: "auto", width: "72.30%" }}>
        {/* //pdf */}

        <Box>
          {assetDetail !== null && (
            <>
              <Box sx={{ marginTop: "80px" }}>
                <Breadcrumb detail={brudcrumDetail} />
              </Box>

              <Box className="b-container-completedAssetDetails__header">
                <Box>
                  <Typography
                    sx={{
                      lineHeight: " 0.5",
                      letterSpacing: "normal",
                      fontFamily: "GothamBlack",
                      fontWeight: "900",
                      color: "#4a4a4a",
                      fontSize: "30px",
                    }}>
                    Asset Tracker Status Detail
                  </Typography>
                </Box>
              </Box>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  marginTop: "30px",
                }}>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      fontFamily: "GothamBook",
                      fontSize: "20px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: " -0.17px",
                      color: "#262262",
                      marginRight: 3,
                    }}>
                    {assetDetail.title}
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box>
                    <Stack direction="row" spacing={2}>
                      <Grid
                        item
                        lg={4}
                        sx={{
                          color: "#216fb4",
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        onClick={printDocument}>
                        <SaveAltSharpIcon />
                        <Typography
                          sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            marginLeft: "2px",
                            fontFamily: "GothamBook",
                          }}>
                          PDF Screenshot
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        lg={3}
                        sx={{
                          color: "#216fb4",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleClickpop}>
                        <ContentCopySharpIcon />
                        <Typography
                          sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            fontFamily: "GothamBook",
                            marginLeft: 0.2,
                          }}
                          className="b-asset-detail-view-container__asset-over-view-section__copy-link">
                          COPY LINK
                        </Typography>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>

              <CopyLinkPopOver openCopyPopup={openCopyPopup} anchorCopy={anchorCopy} />

              <CopyLinkDetail asset={assetDetail} />
            </>
          )}
          <Grid sx={{ paddingBottom: 2, marginTop: 2 }}>
            <Divider sx={{ width: "100%", height: "1px" }} />
            <Footer status />
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default CompeletedAssetsDetail;
