const theem = {
  previousPage: {
    fontFamily: "GothamBook",
    fontSize: "15px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: " normal",
    color: "#216fb4",
  },
  nextPage: {
    fontFamily: "GothamBook",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: "normal",
    lineLeight: "normal",
    letterSpacing: "normal",
    color: "#4a4a4a",
  },
  NavigateNextIcon: {
    marginLeft: "-9px",
    marginRight: "-9px",
    color: "#4a4a4a",
    fontWeight: "normal",
  },
};

export default theem;
