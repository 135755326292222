import React from "react";
import { Typography, Popper, Fade, Paper, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";

function CopyLinkPopOver({ openCopyPopup, anchorCopy }) {
  return (
    <Popper
      className="b-asset-detail-view-container__asset-over-view-section__sucess-popper"
      open={openCopyPopup}
      anchorEl={anchorCopy}
      placement="top-start"
      transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Grid container>
            <Grid item md={12}>
              <Paper className="b-asset-detail-view-container__asset-over-view-section__popper-paper">
                <Grid container>
                  <Grid item md={3.2} pr={0.5} pt={1} pb={1} pl={1}>
                    <CheckCircleIcon
                      sx={{
                        color: "white",
                        fontSize: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={8.8}
                    pr={1}
                    pt={1}
                    pb={1}
                    className="b-asset-detail-view-container__asset-over-view-section__text-align-left">
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                      className="b-asset-detail-view-container__asset-over-view-section__success">
                      Success!
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                      }}
                      className="b-asset-detail-view-container__asset-over-view-section__link-copid">
                      Link copied to clipboard.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Fade>
      )}
    </Popper>
  );
}
CopyLinkPopOver.propTypes = {
  openCopyPopup: PropTypes.bool.isRequired,
  anchorCopy: PropTypes.string.isRequired,
};
export default CopyLinkPopOver;
