import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function PriorityFilter(props) {
  const { title, onClick, titleStyle, image } = props;
  return (
    <Box className="b-priorityFilter-container" sx={{ marginRight: 1.3 }}>
      <button type="button" className="b-priorityFilter-container__button" onClick={onClick}>
        <img src={image} alt="hp" className={titleStyle} />
        <Typography variant="body" className="b-priorityFilter-container__typography-high-priority">
          {title}
        </Typography>
      </button>
    </Box>
  );
}

PriorityFilter.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string]).isRequired,
  titleStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default PriorityFilter;
