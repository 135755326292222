import React, { useState } from "react";
import { Box, Typography, Grid, Tooltip } from "@mui/material";
import { ProgressPopover } from "components/Popovers";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PropTypes from "prop-types";

function AssetProgressIndicator({ asset }) {
  // Assets Progress Indicator
  const [popOverTitle, setPopOverTitle] = useState("");
  const [popOverDuration, setPopOverDuration] = useState("");
  const [extraDetails] = useState("");
  const [progressAnchor, setProgressAnchor] = useState(null);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [progressWidth, setProgressWidth] = useState(0);
  const [transform, setTransform] = useState("left");

  const detailPopOverMouseIn = (event) => {
    setCoordinates([event.clientX, event.clientY]);
    setProgressAnchor(event.currentTarget);
    setPopOverOpen(true);
    setTransform(transform === "left" ? "right" : "left");
  };

  const detailPopOverMouseOut = () => {
    setProgressAnchor(null);
    setPopOverOpen(false);
  };
  const onMouseEnetr = (event, timeLine) => {
    const totalDays = asset.timeline_entries
      .filter((a) => a.info === timeLine.info)
      .reduce((prev, current) => {
        return prev + +current.days;
      }, 0);
    setProgressWidth(timeLine.percentageWidth);
    setPopOverTitle(timeLine.info);
    setPopOverDuration(totalDays);
    detailPopOverMouseIn(event);
  };

  let totalPercntage = 0;

  const os = navigator.userAgent;
  let isMac = false;
  if (os.search("Mac") !== -1) {
    isMac = true;
  }

  const handleStyle = (totalPer) => {
    if (totalPer > 160) {
      return { width: "80.56px", position: "relative ", left: "26px" };
    }
    if (totalPer > 118 && totalPer <= 160) {
      return { width: "80.56px", position: "relative ", left: "29px" };
    }
    if (totalPer > 110 && totalPer <= 118) {
      return { width: "80.56px", position: "relative ", left: "31.2px" };
    }
    if (totalPer > 105 && totalPer <= 110) {
      return { width: "80.56px", position: "relative ", left: "33.5px" };
    }
    if (totalPer > 100 && totalPer <= 105) {
      return { width: "80.56px", position: "relative ", left: "35px" };
    }
    if (totalPer === 100) {
      return { width: "80.56px", position: "relative ", left: "36px" };
    }
    if (totalPer >= 98 && totalPer <= 99) {
      return { width: "80.56px", position: "relative ", left: isMac ? "0px" : "5px" };
    }
    if (totalPer >= 96 && totalPer <= 97) {
      return { width: "80.56px", position: "relative ", left: isMac ? "-2px" : "5px" };
    }
    if (totalPer === 95) {
      return { width: "80.56px", position: "relative ", left: isMac ? "-9px" : "1px" };
    }

    return {
      width: "80.56px",
      marginTop: totalPer < 10 && "40px",
      marginLeft: "-40px",
    };
  };
  return (
    <>
      <Grid item md={11} pt="10px" xs={12} className="b-assetproIndicator-container">
        <Grid
          item
          md={12}
          sx={{
            display: "-webkit-flex",
          }}>
          <Box className="progress-bar-line" />
          <Box
            sx={{
              display: "-webkit-flex",
              bgcolor: "#EDEDF5",
              width: "100%",
              height: "20px",
            }}
            onMouseEnter={(event) => {
              setPopOverTitle("Estimated Days Remaining");
              setPopOverDuration(asset.remaining_days);
              detailPopOverMouseIn(event);
            }}
            onMouseLeave={detailPopOverMouseOut}>
            {asset.timeline_entries.map((timeLine) => {
              return (
                <Box
                  sx={{
                    bgcolor: `${timeLine?.color}`,
                    width: `${timeLine?.percentageWidth}%`,
                    height: "100%",
                    cursor: "pointer",
                  }}
                  aria-owns={popOverOpen ? "mouse-over-progress" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(event) => onMouseEnetr(event, timeLine)}
                  onMouseLeave={detailPopOverMouseOut}
                />
              );
            })}
          </Box>
        </Grid>

        <Grid item md={12} sx={{ display: "-webkit-flex", textAlign: "center", width: "100%" }}>
          {asset.timeline_entries.map((timeLine, index) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            totalPercntage += timeLine?.percentageWidth;
            return (
              <Box
                sx={{
                  width: `${timeLine?.percentageWidth}%`,
                }}>
                {index === 0 && (
                  <div className="b-assetproIndicator-container__asset-submission-date">
                    <Typography
                      variant="body"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontFamily: " GothamBook",
                        color: "#262262",
                      }}>
                      {asset.submission_date_formatted}
                    </Typography>
                  </div>
                )}
              </Box>
            );
          })}
          <Tooltip
            title={
              <Typography fontFamily="GothamBook" fontSize={12}>
                **Represents cumulative business days that do not include weekends, holidays, and
                other non-business days.
              </Typography>
            }>
            <Box sx={() => handleStyle(totalPercntage)}>
              <ArrowDropUpIcon
                className="b-assetproIndicator-container__arrow-indicator"
                sx={{
                  width: "30px",
                  height: "30px",
                  marginTop: "-15px",
                }}
              />
              <Typography
                className="b-assetproIndicator-container__typgraphy-today"
                variant="body"
                sx={{
                  fontSize: "14px",
                  display: "block",
                  marginTop: "-15px",
                  lineHeight: "16px",
                }}>
                TODAY
              </Typography>
              <Typography
                className="b-assetproIndicator-container__typography-till-today"
                variant="body1"
                sx={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "block",
                  overflow: "visible",
                }}>
                {asset.days_till_today} days
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>

      <ProgressPopover
        pfinalanchorEl={progressAnchor}
        popOverOpen={popOverOpen}
        label={popOverTitle}
        duration={popOverDuration}
        extraDetails={extraDetails}
        coordinates={coordinates}
        progressWidth={progressWidth}
        transform={transform}
      />
    </>
  );
}

AssetProgressIndicator.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default AssetProgressIndicator;
