import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { CircularProgress } from "@mui/material";

import { API_URL } from "../../api/endpoints";

function LoginLanding() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userFound, setUserFound] = useState(false);
  const cb = `main-login-landing__background-container-${Math.round(Math.random() * 4)} profile`;

  useEffect(() => {
    if (authState && authState.isAuthenticated && localStorage.getItem("user_id") === null) {
      const { idToken, accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
      if (!idToken || !idToken?.claims) {
        <Navigate to="/" />;
      }

      fetch(`${API_URL}/api/rest-endpoint/get/user/${idToken?.claims?.email}`, {
        headers: { Authorization: `Bearer ${accessToken?.accessToken}` },
      })
        .then((res) => res.json())
        .then((userInfo) => {
          localStorage.setItem("user_id", userInfo[0]?.id);
          localStorage.setItem("user_roles", userInfo[0]?.roles);
          setUserFound(true);
        })
        .catch((error) => new Error(error));
      if (sessionStorage.getItem("profile") === null) {
        oktaAuth.getUser().then(() => {
          fetch(`${process.env.REACT_APP_OKTA_BASE_URL}/api/v1/users/${idToken.claims.email}`, {
            headers: {
              Accept: "application/json",
              Authorization: `SSWS ${process.env.REACT_APP_OKTA_API_TOKEN}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
            mode: "cors",
          })
            .then((res) => res.json())
            .then(({ profile }) => {
              const partners = profile;
              const z = Object.keys(profile)
                .filter((k) => {
                  return k.indexOf("partner2") === 0;
                })
                .reduce((newData, k) => {
                  const data = newData;
                  data[k] = partners[k];
                  return data;
                }, {});
              const partnersFilters = Object.keys(z).map((a) => {
                if (Array.isArray(z[a])) {
                  return z[a].join(":");
                }
                return z[a];
              });
              sessionStorage.setItem("partners", [...new Set(partnersFilters)].join(","));
            });
        });
      }
    } else {
      setUserFound(true);
    }
  }, [authState, oktaAuth]);
  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const myParam = useLocation().search;
  const ss = new URLSearchParams(myParam).get("iss");
  if (ss != null) {
    login();
  }
  if (!authState) {
    return (
      <CircularProgress
        size={100}
        sx={{
          color: "#262262",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }

  if (authState && !authState.isAuthenticated && !authState.isPending) {
    const finalRender = (
      <div className="main-login-landing">
        <div className={`${cb} main-login-landing__inner-main-container`}>
          <div className="main-login-landing__login-box-container">
            <section className="main-login-landing__main-login-card">
              <img
                src="/static/media/AssetTrackerLogo.3e59a2808cdcace3531e.webp"
                alt="Asset Tracker Logo"
              />

              <button className="main-login-landing__login-btn" type="button" onClick={login}>
                Log in
              </button>
            </section>
          </div>
        </div>
      </div>
    );

    return finalRender;
  }

  if (!userFound) {
    return (
      <CircularProgress
        size={100}
        sx={{
          color: "#262262",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }
  return <Navigate to="/home" />;
}

export default LoginLanding;
