import React from "react";
import { FixedSizeList as List } from "react-window";
// import InfiniteLoader from "react-window-infinite-loader";
import PropTypes from "prop-types";

// const LOADING = 1;
// const LOADED = 2;
// const itemStatusMap = {};

// const isItemLoaded = (index) => !!itemStatusMap[index];

// const loadMoreItems = (startIndex, stopIndex) => {
//   for (let index = startIndex; index <= stopIndex; index += 1) {
//     itemStatusMap[index] = LOADING;
//   }
//   return new Promise((resolve) =>
//     setTimeout(() => {
//       for (let index = startIndex; index <= stopIndex; index += 1) {
//         itemStatusMap[index] = LOADED;
//       }
//       resolve();
//     }, 2500)
//   );
// };

function ListItemsLoader(props) {
  const {
    activeAssets,
    renderData,
    itemSize = 215,
    width = "100%",
    height = 500,
    className = "detail-view-container__scroller",
  } = props;
  return (
    // <InfiniteLoader
    //   isItemLoaded={isItemLoaded}
    //   itemCount={activeAssets.length}
    //   loadMoreItems={loadMoreItems}>
    //   {({ onItemsRendered, ref }) => (
    <List
      width={width}
      height={height}
      itemCount={activeAssets?.length}
      itemSize={itemSize}
      overscanCount={activeAssets?.length}
      style={{
        overflowX: "hidden",
      }}
      // onItemsRendered={onItemsRendered}
      // ref={ref}
      className={className}>
      {renderData}
    </List>
    //   )}
    // </InfiniteLoader>
  );
}
ListItemsLoader.propTypes = {
  activeAssets: PropTypes.arrayOf.isRequired,
  itemSize: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  renderData: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default ListItemsLoader;
