import React from "react";
import { Popover, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

function ProgressPopover({ label, duration, extraDetails, popOverOpen, coordinates, transform }) {
  const { innerHeight } = window;

  const topStyleClasses =
    coordinates[1] + 20 >= innerHeight && transform !== "left"
      ? "p-progress-popover__p-final-upper-left-paper"
      : "p-progress-popover__p-final-upper-right-paper";

  const bottomStyleClasses =
    transform !== "left"
      ? "p-progress-popover__p-final-paper "
      : "p-progress-popover__p-final-right-paper ";

  return (
    <Popover
      id="mouse-over-progress"
      sx={{
        pointerEvents: "none !important",
        marginTop: -1,
      }}
      open={popOverOpen}
      anchorReference="anchorPosition"
      anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        horizontal: transform,
      }}
      disableRestoreFocus
      classes={
        coordinates[1] + 90 >= innerHeight
          ? { paper: topStyleClasses }
          : { paper: bottomStyleClasses }
      }>
      <Grid container>
        <Grid item md={12}>
          <Typography
            className="p-progress-popover__text-heading"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              fontFamily: "GothamBold",
            }}>
            {label}
          </Typography>
        </Grid>

        <Grid item mt={0.5} md={12} sx={{ height: "1px", backgroundColor: "#6cace4" }} />

        <Grid item mt={0.5} md={6} />

        {/* {label === "Prep Final Files" && (
          <Grid item md={12} mt={1}>
            <Typography
              sx={{
                lineHeight: "16px",
                letterSpacing: "-0.17px",
                color: "#262262",
                fontSize: "12px",
                fontFamily: "GothamBook ",
              }}>
              Asset Portal QC and Program Upload
            </Typography>
          </Grid>
        )} */}

        {extraDetails !== "" && (
          <Grid item md={12} mt={1}>
            <Typography
              sx={{
                lineHeight: "16px",
                letterSpacing: "-0.17px",
                color: "#262262",
                fontSize: "12px",
                fontFamily: "GothamBook ",
              }}>
              {extraDetails}
            </Typography>
          </Grid>
        )}
        <Grid item md={12} mt={0.5}>
          <Typography
            sx={{
              lineHeight: "16px",
              letterSpacing: "-0.17px",
              color: "#262262",
              fontSize: "12px",
              fontFamily: "GothamBook ",
            }}>
            {/* {label === "Prep Final Files" && "Typical Duration*: "} */}
            {duration} days
          </Typography>
        </Grid>
      </Grid>
    </Popover>
  );
}

ProgressPopover.propTypes = {
  label: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  extraDetails: PropTypes.string.isRequired,
  popOverOpen: PropTypes.bool.isRequired,
  coordinates: PropTypes.arrayOf.isRequired,
  transform: PropTypes.string.isRequired,
};

export default ProgressPopover;
