import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Container, Box, Typography, Divider, Grid } from "@mui/material";
import PropTypes from "prop-types";

import AssetTrackerLogo from "images/logos/AssetTrackerLogo.webp";
import { getCurentDateTime } from "utils/utilityFunctions";
import PdfDetailComponent from "components/PdfGenerator/PdfComponent/PdfDetailComponent";

const ActiveAssetPdf = forwardRef((props, ref) => {
  const { assetDetail } = props;
  const pdfContainerRef = useRef(null);
  const cieldPdfContainerRef = useRef(null);
  const os = navigator.userAgent;
  let isMac = false;
  if (os.search("Mac") !== -1) {
    isMac = true;
  }
  useImperativeHandle(ref, () => ({
    parentref: pdfContainerRef,
    chieldpdfRef: cieldPdfContainerRef,
  }));

  const getMarginTopValue = () => {
    const referenceEntitiesLength = assetDetail.reference_entities.length;

    if (referenceEntitiesLength > 6 && referenceEntitiesLength <= 10) {
      return "57%";
    }
    if (referenceEntitiesLength > 10) {
      return "54%";
    }
    return "66%";
  };
  return (
    <Box
      sx={{ display: "none", width: isMac ? "94%" : "120%", margin: "auto" }}
      ref={pdfContainerRef}>
      <Container ref={cieldPdfContainerRef}>
        {assetDetail !== null && (
          <Box sx={{ marginLeft: "80px", paddingTop: "1px" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "27px",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    fontFamily: "GothamBook",
                    fontSize: "14px",
                    fontWeight: " normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: " 1.43",
                    letterSpacing: " -0.12px",
                    color: "#4a4a4a",
                    marginLeft: "-36px",
                  }}>
                  {getCurentDateTime()}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "GothamBook",
                    fontSize: "14px",
                    fontWeight: " normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: " 1.43",
                    letterSpacing: " -0.12px",
                    color: "#4a4a4a",
                    marginRight: "80px",
                  }}>
                  Asset Portal – Asset Tracker
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: "60px" }}>
              <img
                src={AssetTrackerLogo}
                alt="hp"
                className="AssetTrackerLogo"
                style={{
                  width: "200px",
                  height: "80px",
                  cursor: "pointer",
                  paddingTop: 8,
                }}
              />
            </Box>
            <Box className="b-container-completedAssetDetails__header">
              <Box>
                <Typography
                  sx={{
                    lineHeight: " 0.5",
                    letterSpacing: "normal",
                    fontFamily: "GothamBlack",
                    fontWeight: "900",
                    color: "#4a4a4a",
                    fontSize: "30px",
                  }}>
                  Asset Tracker Status Detail
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: "30px",
              }}
              spacing={2}>
              <Grid item xs={10}>
                {" "}
                <Box
                  sx={{
                    fontFamily: "GothamBook",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: " -0.17px",
                    color: "#262262",
                  }}>
                  {assetDetail.title}
                </Box>
              </Grid>
            </Grid>
            <PdfDetailComponent asset={assetDetail} />
            <Box
              sx={{
                marginTop: getMarginTopValue(),
                marginLeft: "-18px",
                marginRight: "-18px",
                paddingTop: "30px",
              }}>
              <Divider sx={{ marginTop: "25px", marginLeft: "-25px" }} />
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.43",
                    letterSpacing: " -0.12px",
                    color: "#4a4a4a",
                    marginLeft: "-25px",
                  }}>
                  {`${process.env.REACT_APP_DOMAIN_URL}/asset/detail/${assetDetail.task_id}`}
                </Typography>
                <Typography>1/1</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
});
ActiveAssetPdf.propTypes = {
  assetDetail: PropTypes.objectOf.isRequired,
};
export default ActiveAssetPdf;
