import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Popover,
} from "@mui/material";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";

import { getRequestTypesAssets } from "api/apiMethods";

function FilterPopOver({
  requestTypeAnchorEl,
  openRequestType,
  setRequestTypeOpen,
  selectedRequestTypes,
  setSelectedRequestTypes,
  setFilterNames,
  filterNames,
}) {
  const [requestDetails, setRequestDetails] = useState(null);
  const [filternamesRequesType, setfilternamesRequest] = useState([]);
  const handleClose = () => {
    setRequestTypeOpen(false);
  };

  const toggleSelectionCheckbox = (event) => {
    const filterlist = [...filternamesRequesType];
    if (event.target.checked) {
      setSelectedRequestTypes((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      filterlist.push(event.target.value);
      setfilternamesRequest(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);
      setfilternamesRequest(filterlist);
      setSelectedRequestTypes((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const handleClearAll = () => {
    setSelectedRequestTypes([]);
    const filterNameList = [...filterNames];
    const filterListName = filterNameList.filter((item) => {
      const isexistItem = filternamesRequesType.find((items) => items === item);
      if (isexistItem) {
        return item !== isexistItem;
      }
      return item;
    });
    setFilterNames(filterListName);
  };
  useEffect(() => {
    getRequestTypesAssets().then((response) => {
      if (response !== false) {
        setRequestDetails(response);
      }
    });
  }, []);
  return (
    <Popover
      id="filterPopOver"
      className="b-container-filterPopOver"
      open={openRequestType}
      anchorEl={requestTypeAnchorEl}
      onClose={handleClose}
      transitionDuration={1}
      classes={{ paper: "completed-filter-popover" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}>
      <Box className="b-container-filterPopOver__container ">
        <Grid container className="b-container-filterPopOver__container__innerContainer">
          <Grid item md={5.5}>
            <Button
              onClick={handleClose}
              className="b-container-filterPopOver__container__innerContainer__gridButton"
              sx={{
                marginTop: "-38px",
                zIndex: "300",
                width: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              disableRipple>
              <Typography
                className="b-container-filterPopOver__container__gridButton__text"
                sx={{ color: "#4a4a4a", fontSize: "14px", fontFamily: "GothamBook" }}>
                REQUEST TYPE
              </Typography>
              <ExpandLessIcon className="b-container-filterPopOver__container__gridButton__expanicon" />
            </Button>
          </Grid>
          <Grid item md={1.9} />
          <Grid item md={4.1}>
            <Button
              className="b-container-filterPopOver__container__underlined"
              sx={{
                borderRadius: "0px",
                color: "#216fb4",
                fontFamily: "GothamBook",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
              onClick={handleClearAll}>
              Clear All
            </Button>
          </Grid>
          <Grid
            item
            md={12}
            mb={3}
            className="p-request-pop-over__request-type-box__request-scroll-body">
            <FormGroup>
              {requestDetails !== null &&
                requestDetails.map((requestTypes) => (
                  <FormControlLabel
                    classes={{
                      label: "b-container-filterPopOver__filter-text",
                    }}
                    // sx={{
                    //   pt: 0.8,
                    //   pl: 2.75,
                    //   fontFamily: "GothamBook",
                    //   fontSize: "14px",
                    //   fontWeight: "normal",
                    //   fontStretch: "normal",
                    //   fontStyle: "normal",
                    //   lineHeight: "normal",
                    //   letterSpacing: "normal",
                    // }}
                    control={
                      <Checkbox
                        onChange={toggleSelectionCheckbox}
                        value={requestTypes.internal_name}
                        checked={selectedRequestTypes.includes(requestTypes.internal_name)}
                        icon={
                          <CheckBoxOutlineBlankSharpIcon
                            sx={{
                              width: "16px",
                              height: "16px",
                              color: "#216FB4",
                            }}
                          />
                        }
                        checkedIcon={
                          <CheckBoxSharpIcon
                            sx={{
                              width: "16px",
                              height: "16px",
                              color: "#216FB4",
                            }}
                          />
                        }
                      />
                    }
                    label={requestTypes.name}
                  />
                ))}
            </FormGroup>
          </Grid>{" "}
        </Grid>
      </Box>
    </Popover>
  );
}
FilterPopOver.propTypes = {
  requestTypeAnchorEl: PropTypes.bool.isRequired,
  openRequestType: PropTypes.bool.isRequired,
  setRequestTypeOpen: PropTypes.func.isRequired,
  selectedRequestTypes: PropTypes.bool.isRequired,
  setSelectedRequestTypes: PropTypes.bool.isRequired,
  setFilterNames: PropTypes.func.isRequired,
  filterNames: PropTypes.arrayOf.isRequired,
};
export default FilterPopOver;
