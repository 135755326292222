import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Local Imports
import { ListItemText, Typography } from "@mui/material";

function MenubarItem({ title, link }) {
  return (
    <NavLink to={`${link}`} className="nav-bar-link">
      <ListItemText>
        <Typography variant="body" className="nav-bar-link__nav-bar-link-text">
          {title}
        </Typography>
      </ListItemText>
    </NavLink>
  );
}

MenubarItem.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

export default MenubarItem;
