import React from "react";
import { Popover, Grid, Typography, Divider } from "@mui/material";
import PropTypes from "prop-types";

function CompleteProgressPopover({ label, duration, hoveropen, coordinates, onClose }) {
  return (
    <Popover
      id="mouse-over-popover1"
      sx={{
        pointerEvents: "none",
        marginTop: -1,
      }}
      open={hoveropen}
      anchorReference="anchorPosition"
      anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      onClose={onClose}
      disableRestoreFocus>
      <Grid container className="complete-progress-popover__bubble-grid ">
        <Grid item md={12}>
          <Typography
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              fontFamily: "GothamBold",
            }}>
            {label}
          </Typography>
        </Grid>
        <Divider sx={{ width: "100%", height: "1px", borderColor: "#6cace4" }} />
        <Grid item md={12}>
          <Typography
            sx={{
              lineHeight: "16px",
              letterSpacing: "-0.17px",
              color: "#262262",
              fontSize: "12px",
              fontFamily: "GothamBook ",
            }}>
            {`${duration} Days`}
          </Typography>
        </Grid>
      </Grid>
    </Popover>
  );
}

CompleteProgressPopover.propTypes = {
  label: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  coordinates: PropTypes.arrayOf.isRequired,
  onClose: PropTypes.func.isRequired,
  hoveropen: PropTypes.func.isRequired,
};
export default CompleteProgressPopover;
