import React, { useEffect, useState } from "react";
import HighLogo from "images/logos/High.webp";
import LowLogo from "images/logos/Low.webp";
import img from "images/filter.svg";
import { Container, Box, Typography, Grid, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterPopOver from "components/CompeleteAssets/FilterPopOver";
import { completeAssetContainer } from "views/CompleteAssets/completedAssetTheem";
import {
  getAffiliationsAssets,
  getCompeletedAssetsByUser,
  getCompletedAssetsFilterCount,
  getRequesterAssets,
} from "api/apiMethods";
import Footer from "components/Footer/Footer";
import FilterNamePill from "components/FilterNamePill/FilterNamePill";
import AssetsProgressBarStatus from "components/AssetsProgress/AssetsProgress";
import FilterModal from "components/FilterModal/FilterModal";
import ListItemsLoader from "components/ListItemsLoader/ListItemsLoader";
import DropdownOptionsSort from "components/DropdownOptionsSort/DropdownOptionsSort";
import Heading from "components/HeadingComponent/Heading";

const options = ["Newest Requests", "Low  Priority", "High Priority", "Oldest Requests"];

function Completedassets() {
  const [state, setState] = useState({
    open: false,
  });
  const [requestTypeAnchorEl, setrequestTypeAnchorEl] = useState(null);
  const [openFilterPopOver, setOpenFilterPopOver] = useState(false);
  const [selectedRequestTypes, setSelectedRequestTypes] = useState(
    JSON.parse(sessionStorage.getItem("selectedRequestTypesCompleted")) || []
  );
  const [selectedSortValues, setSelectedSortValues] = useState(
    JSON.parse(sessionStorage.getItem("selectedSortCompleted")) || []
  );
  const [assets, setAssets] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    JSON.parse(sessionStorage.getItem("selectedStatusCompleted")) || []
  );
  console.log("SESSION PARTNERS: ", sessionStorage.getItem("partners").length > 0);
  const [selectedPartner, setSelectedPartner] = useState(
    sessionStorage.getItem("partners").split(":")
    // JSON.parse(sessionStorage.getItem("selectedPartnersCompleted")) || []
  );
  console.log("CURRENT VALUE OF selectedPartner: ", selectedPartner);
  const [selectedRequester, setSelectedRequester] = useState(
    JSON.parse(sessionStorage.getItem("selectedRequesterCompleted")) || []
  );
  const [filterNames, setFilterNames] = useState(
    JSON.parse(sessionStorage.getItem("selectedFiltersCompleted")) || []
  );
  const [allFilterNamesModal, setAllFilterNamesModal] = useState(
    JSON.parse(sessionStorage.getItem("selectedFiltersCompleted")) || []
  );
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [filternamesPartner, setfilternamesPartner] = useState([]);
  const [filternamesRequester, setfilternamesRequester] = useState([]);
  const [requesterDetails, setRequesterDetails] = useState(null);
  const [filterColumnList, setFilterColumnList] = useState([]);
  const [filteredAffiliationArray, setFilteredAffiliationArray] = useState();
  const [filteredRequesterArray, setFilteredRequesterArray] = useState();
  const [activeAssetsFilterCount, setActiveAssetsFilterCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);

  useEffect(() => {
    if (allFilterNamesModal.length && assets.length === 0) {
      setLoading(false);
    }
  }, []);

  const handleClickSort = () => {
    setIsOpenSort((prevState) => !prevState);
  };

  const userId = localStorage.getItem("user_id");

  const userRoles = localStorage.getItem("user_roles");
  const isUserRolesExecutive = userRoles.includes("executive");

  const activeAssetsDataObj = {
    statusIds: selectedStatus.join(":"),
    requestTypes: selectedRequestTypes.join(":"),
    partners: selectedPartner.join(":"),
    requester: selectedRequester.join(":"),
    userId: Number(userId),
    sort: selectedSortValues.join(","),
  };

  const toggleSelectionAffiliationsCheckbox = (event) => {
    const filterlist = [...filternamesPartner];
    if (event.target.checked) {
      setSelectedPartner((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.concat(event.target.value);
      });
      filterlist.push(event.target.value);
      setFilteredAffiliationArray(
        filteredAffiliationArray.filter((item) => item.name !== event.target.value)
      );
      setfilternamesPartner(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);
      filteredAffiliationArray.push({ id: Math.random(1, 10), name: event.target.value });
      setfilternamesPartner(filterlist);
      setSelectedPartner((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const toggleSelectionRequesterCheckbox = (event) => {
    const filterlist = [...filternamesRequester];
    if (event.target.checked) {
      setSelectedRequester((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilteredRequesterArray(
        filteredRequesterArray.filter((item) => item.name !== event.target.value)
      );
      filterlist.push(event.target.value);
      setfilternamesRequester(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);

      filteredRequesterArray.push({ id: Math.random(1, 10), name: event.target.value });
      setfilternamesRequester(filterlist);
      setSelectedRequester((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const handleClickOpen = () => {
    setState({ open: true });
  };

  const handleClose = () => {
    setState({ open: false });
    setLoading(true);
    getCompeletedAssetsByUser(activeAssetsDataObj)
      .then((response) => {
        setLoading(false);
        if (response !== null) {
          setAssets(response.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  sessionStorage.setItem("selectedFiltersCompleted", JSON.stringify(filterNames));
  sessionStorage.setItem("selectedRequestTypesCompleted", JSON.stringify(selectedRequestTypes));
  sessionStorage.setItem("selectedRequesterCompleted", JSON.stringify(selectedRequester));
  sessionStorage.setItem("selectedPartnersCompleted", JSON.stringify(selectedPartner));
  sessionStorage.setItem("selectedSortCompleted", JSON.stringify(selectedSortValues));

  const onClickCrossButton = () => {
    setState({ open: false });
    setSelectedStatus([]);
    setSelectedRequestTypes([]);
    setFilterColumnList([]);
    setSelectedPartner([]);
    setSelectedRequester([]);

    const filterNameList = [...filterNames];
    const filterListName = filterNameList.filter((item) => {
      const isexistItem = allFilterNamesModal.find((items) => items === item);
      if (isexistItem) {
        return item !== isexistItem;
      }
      return item;
    });
    setFilterNames(filterListName);
    setAllFilterNamesModal([]);
    sessionStorage.removeItem("selectedFiltersCompleted");
    sessionStorage.removeItem("selectedRequestTypesCompleted");
    sessionStorage.removeItem("selectedRequesterCompleted");
    sessionStorage.removeItem("selectedPartnersCompleted");
  };

  useEffect(() => {
    if (partnerDetails == null) {
      getAffiliationsAssets(userId).then((response) => {
        if (response !== false) {
          setPartnerDetails(response);
          setFilteredAffiliationArray(response);
        }
      });
    }

    if (requesterDetails == null) {
      getRequesterAssets().then((response) => {
        if (response !== false) {
          setRequesterDetails(response);
          setFilteredRequesterArray(response);
        }
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getCompletedAssetsFilterCount(activeAssetsDataObj).then((response) => {
      setLoading(false);
      if (response !== null) {
        setActiveAssetsFilterCount(response.count);
      }
    });
  }, [selectedStatus, selectedRequestTypes, selectedRequester, selectedPartner]);

  useEffect(() => {
    setLoading(true);
    getCompeletedAssetsByUser(activeAssetsDataObj)
      .then((response) => {
        setLoading(false);
        if (response !== null) {
          setAssets(response.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [state, selectedSortValues]);

  const handleClick = (event) => {
    setrequestTypeAnchorEl(event.currentTarget);
    setOpenFilterPopOver(true);
  };

  const filterRemove = (event, filter) => {
    event.preventDefault();
    setFilterNames((prevState) => {
      return prevState.filter((a) => {
        if (typeof filter === "object") {
          return a.wrike_status_id !== filter.wrike_status_id;
        }
        return a !== filter;
      });
    });

    setAllFilterNamesModal((prevState) => {
      return prevState.filter((a) => {
        if (typeof filter === "object") {
          return a.wrike_status_id !== filter.wrike_status_id;
        }
        return a !== filter;
      });
    });

    setSelectedSortValues((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedPartner((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedRequester((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedRequestTypes((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedStatus((prevState) => {
      return prevState.filter((a) => a !== filter.wrike_status_id);
    });
  };

  const highPriority = () => {
    setSelectedSortValues(["High Priority"]);
    setFilterNames((prevState) => {
      return prevState.filter(
        (a) =>
          a !== "Status" &&
          a !== "Newest Requests" &&
          a !== "Oldest Requests" &&
          a !== "High Priority" &&
          a !== "Low Priority"
      );
    });
    setFilterNames((prevState) => {
      return prevState.concat("High Priority");
    });
  };

  const lowPriority = () => {
    setSelectedSortValues(["Low Priority"]);
    setFilterNames((prevState) => {
      return prevState.filter(
        (a) =>
          a !== "Status" &&
          a !== "Newest Requests" &&
          a !== "Oldest Requests" &&
          a !== "High Priority" &&
          a !== "Low Priority"
      );
    });
    setFilterNames((prevState) => {
      return prevState.concat("Low Priority");
    });
  };

  const { compeleteAssets, filterButtn, sortButton, allAssetsUniqeSection } =
    completeAssetContainer;

  const breadcrumbs = {
    previousPageUrlTitle: "Completed Assets",
    previousPageBackUrl: "/completed-assets",
    nextPageUrlTitle: "Assets Details",
    nexPagetUrl: "",
  };

  const os = navigator.userAgent;
  let system = "Windows";
  if (os.search("Mac") !== -1) {
    system = "mac";
  }

  const progressLineSubmissionComplete =
    system === "Windows"
      ? "detail-view-container__grid_completed-progress-border"
      : "detail-view-container__grid_completed-progress-mac-border";

  const progressLineDueComplete =
    system === "Windows"
      ? "detail-view-container__grid_completed-progress1-border"
      : "detail-view-container__grid_completed-progress1-mac-border";

  const progressLineDelayComplete =
    system === "Windows"
      ? "detail-view-container__grid_completed-progress2-border"
      : "detail-view-container__grid_completed-progress2-mac-border";

  const renderList = ({ index, key, style }) => {
    const data = assets[index];
    let percent;

    if (data.days_till_today >= 1.5 * data.total_days) {
      percent = 100;
    } else if (data.days_till_today > data.total_days) {
      const newP = data.days_till_today / data.total_days;
      percent = 55 + newP * 15;
    } else if (data.days_till_today < data.total_days) {
      percent = (data.days_till_today / data.total_days) * 67;
    } else if (data.days_till_today === data.total_days) {
      percent = 66.2;
    }

    return (
      <Box sx={{ paddingRight: "2rem" }} key={key} style={style}>
        <AssetsProgressBarStatus
          title={data.title}
          description={data.status_description}
          barColor={data.color}
          barValue={percent}
          taskPriority={data.task_importance}
          data={data}
          completedAsset
          breadcrumbsDetail={breadcrumbs}
          curentPageLink="/completed-assets/assetdetail"
        />
      </Box>
    );
  };

  return (
    <Container
      className="b-compeleteassets-container"
      sx={{ margin: "auto", width: "72.30%", position: "relative" }}>
      <Box>
        <Grid container>
          <Grid xs={12} className="b-compeleteassets-container__compeleteAssets">
            <Typography sx={compeleteAssets.header}>
              {assets !== null ? assets.length : ""} Completed Asset
              {assets !== null && assets.length === 1 ? "" : "s"}
            </Typography>

            <Typography sx={compeleteAssets.text}>(last 30 days)</Typography>
          </Grid>
        </Grid>

        {filterNames && <FilterNamePill filterNames={filterNames} filterRemove={filterRemove} />}

        <Box
          container
          className="b-compeleteassets-container__gridcontainer"
          sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <Box item className="b-compeleteassets-container__priority-container">
              <img
                src={HighLogo}
                alt="hp"
                className="b-compeleteassets-container__priority-container__lowimg"
              />
              <Typography
                variant="body"
                className="b-compeleteassets-container__priority-container__high"
                onClick={highPriority}>
                High priority
              </Typography>
            </Box>
            <Box
              item
              className="b-compeleteassets-container__priority-container"
              sx={{ marginLeft: "20px" }}>
              <img
                src={LowLogo}
                alt="lp"
                className="b-compeleteassets-container__priority-container__lowimg"
              />
              <Typography
                className="b-compeleteassets-container__priority-container__low"
                variant="body"
                onClick={lowPriority}>
                Low priority
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            {!isUserRolesExecutive ? (
              <Box
                className="b-compeleteassets-container__filterButtn"
                aria-describedby="filterPopOver"
                onClick={handleClick}>
                <Typography ml={1.7} sx={filterButtn}>
                  REQUEST TYPE
                </Typography>
                <ExpandMoreIcon sx={sortButton.iccon} />
              </Box>
            ) : (
              <Box
                className="b-compeleteassets-container__filterButtn"
                aria-describedby="filterPopOver"
                onClick={handleClickOpen}>
                <Typography ml={1.7} sx={filterButtn}>
                  Filter
                </Typography>
                <img
                  src={img}
                  className="b-compeleteassets-container__filterButtn__image "
                  alt="img"
                />
              </Box>
            )}
            <Box className="b-compeleteassets-container__gridmargin" />

            <Box className="b-compeleteassets-container__sortButton ">
              <DropdownOptionsSort
                selectedSortValues={selectedSortValues}
                setSelectedSortValues={setSelectedSortValues}
                setFilterNames={setFilterNames}
                data={options}
                handleClickSort={handleClickSort}
                isOpenSort={isOpenSort}
                setIsOpenSort={setIsOpenSort}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Detail Body */}
      {(loading || (assets <= 0 && allFilterNamesModal.length <= 0)) && (
        <CircularProgress
          size={100}
          sx={{
            color: "#262262",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}

      {assets?.length <= 0 && (
        <Heading
          title="No Record Available"
          titleStyle="detail-view-container__no-assets-heading"
        />
      )}

      {assets?.length > 0 && (
        <Box className="b-compeleteassets-container__pagebody">
          <Box className={progressLineSubmissionComplete} md={0.12} />
          <Box className={progressLineDueComplete} md={0.12} />
          <Box className={progressLineDelayComplete} md={0.12} />
          <ListItemsLoader
            activeAssets={assets}
            renderData={renderList}
            itemSize={55}
            width="100%"
            height={514}
            className="detail-view-container__scroller-complete"
          />
        </Box>
      )}

      <Box
        className="b-compeleteassets-container__grid_progress-border-indicator-submission"
        md={0.12}
      />
      <Box className="b-compeleteassets-container__grid_progress-border-indicator-sop" md={0.12} />
      <Box
        className="b-compeleteassets-container__grid_progress-border-indicator-extended"
        md={0.12}
      />
      <Box className="b-compeleteassets-container__daysSection" />
      <hr />
      <Grid sx={{ marginTop: "-15px" }} container alignItems="center">
        <Grid item md={7.89}>
          <Typography
            sx={{
              fontFamily: "GothamBook",
              fontSize: "12px",
              lineHeight: "1.67",
              letterSpacing: "-0.17px",
              color: "#262162",
              marginTop: "7px",
            }}>
            Submission Date
          </Typography>
        </Grid>
        <Grid item md={system === "mac" ? 1.8 : 1.9}>
          <Typography
            sx={{
              fontFamily: "GothamBook",
              fontSize: "12px",
              lineHeight: "1.67",
              letterSpacing: " -0.17px",
              color: "#262162",
              marginTop: "7px",
            }}>
            SOP Due Date
          </Typography>
        </Grid>
        <Grid item md={1.56}>
          <Typography
            sx={{
              fontFamily: "GothamBook",
              fontSize: "12px",
              lineHeight: "1.67",
              letterSpacing: " -0.17px",
              color: "#262162",
              marginTop: "7px",
              marginLeft: "-0.8rem",
              display: "flex",
              justifyContent: "center",
            }}>
            Extended
          </Typography>
        </Grid>
      </Grid>
      <Box className="b-compeleteassets-container__allAssetsUniqeSection">
        <Typography sx={allAssetsUniqeSection.text}>
          *All assets are unique. Typical durations are based on past projects and may not apply to
          yours.
        </Typography>
      </Box>
      {/* Requset Type Popover */}
      <FilterPopOver
        filterNames={filterNames}
        requestTypeAnchorEl={requestTypeAnchorEl}
        openRequestType={openFilterPopOver}
        setRequestTypeOpen={setOpenFilterPopOver}
        selectedRequestTypes={selectedRequestTypes}
        setSelectedRequestTypes={setSelectedRequestTypes}
        setFilterNames={setFilterNames}
      />

      {/* Filter Modal */}
      <FilterModal
        openModal={state.open}
        handleClose={handleClose}
        selectedStatus={selectedStatus}
        selectedRequestTypes={selectedRequestTypes}
        filterNames={filterNames}
        setSelectedStatus={setSelectedStatus}
        setFilterNames={setFilterNames}
        setSelectedRequestTypes={setSelectedRequestTypes}
        selectedPartner={selectedPartner}
        setSelectedPartner={setSelectedPartner}
        selectedRequester={selectedRequester}
        setSelectedRequester={setSelectedRequester}
        numberOfSelectedAssets={assets.length}
        setPartnerDetails={setPartnerDetails}
        setRequesterDetails={setRequesterDetails}
        requesterDetails={requesterDetails}
        partnerDetails={partnerDetails}
        onClickAffiliationCheckBox={toggleSelectionAffiliationsCheckbox}
        onClickRequesterCheckBox={toggleSelectionRequesterCheckbox}
        allFilterNamesModal={allFilterNamesModal}
        setAllFilterNamesModal={setAllFilterNamesModal}
        filteredAffiliationArray={filteredAffiliationArray}
        filteredRequesterArray={filteredRequesterArray}
        filterColumnList={filterColumnList}
        setFilterColumnList={setFilterColumnList}
        onClickCrossButton={onClickCrossButton}
        boolCompletedAssets={false}
        activeAssetsFilterCount={activeAssetsFilterCount}
      />

      <Footer />
    </Container>
  );
}

export default Completedassets;
