import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";

function FilterNamePill({ filterNames, filterRemove }) {
  return (
    <Grid container pt={1.25} pb={1.1875}>
      <Grid item md={12}>
        {filterNames !== undefined &&
          filterNames.map((filter, index) => (
            <span className="b-filterNamesPill-Container__detail-view-asset-names" id="asset2">
              <Typography
                className="b-filterNamesPill-Container__typography-font-filter"
                pl={1}
                pt={0.5}
                pb={0.5}
                pr={1.25}>
                {filter}
              </Typography>{" "}
              <CloseIcon
                sx={{ width: "20px", height: "20px" }}
                id={index}
                onClick={(event) => filterRemove(event, filter)}
                className="detail-view-container__detail-view-close-icon"
              />
            </span>
          ))}
      </Grid>
    </Grid>
  );
}
FilterNamePill.propTypes = {
  filterNames: PropTypes.arrayOf.isRequired,
  filterRemove: PropTypes.func.isRequired,
};

export default FilterNamePill;
