import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function Heading({ title, titleStyle = "", variant = "h1" }) {
  return (
    <Typography variant={variant} className={titleStyle}>
      {title}
    </Typography>
  );
}

Heading.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string]).isRequired,
  titleStyle: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

export default Heading;
