import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import "style/components/FilterType/filtertype.scss";
import FilterImage from "images/Icons/filter.webp";

function FilterTypes(props) {
  const {
    title,
    onClick,
    areia,
    marginLeftProps,
    variants,
    filter = false,
    noOfSelectedFIlters = 0,
  } = props;
  return (
    <Box
      className="g-filter-type__grid-select-btn-request-type"
      onClick={onClick}
      aria-describedby={areia}
      variant={variants}>
      <Typography
        sx={{
          margin: "0px",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.00938em",
          fontSize: "14px",
          cursor: "pointer",
          fontFamily: "GothamBook",
          color: "#4a4a4a",
          marginLeft: marginLeftProps,
          display: "flex",
        }}>
        {filter && noOfSelectedFIlters > 0 && (
          <Box
            sx={{
              marginRight: "3px",
              width: "30px",
              height: "30px",
              borderRadius: "15px",
              background: "#216fb4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-5px",
            }}>
            <Typography variant="body" className="g-filter-type__filter-number">
              {noOfSelectedFIlters}
            </Typography>
          </Box>
        )}
        {title}
      </Typography>
      {filter ? (
        <img src={FilterImage} alt="Filter" className="g-filter-type__filter-image" />
      ) : (
        <ExpandMoreIcon className="g-filter-type__expand-icon-request-type" />
      )}
    </Box>
  );
}

FilterTypes.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string]).isRequired,
  filter: PropTypes.bool.isRequired,
  noOfSelectedFIlters: PropTypes.number.isRequired,
  variants: PropTypes.oneOfType([PropTypes.string]).isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  areia: PropTypes.oneOfType([PropTypes.string]).isRequired,
  marginLeftProps: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

export default FilterTypes;
