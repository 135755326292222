import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

// Local Imports
import Heading from "components/HeadingComponent/Heading";
import { getRequestTypesAssets, getStatusAssets, getApprovalLevels } from "api/apiMethods";
import LinkButton from "components/LinkButton/LinkButton";
import FormControlInput from "components/FormControlInput/FormControlInput";

function FilterModal(props) {
  const {
    openModal,
    handleClose,
    selectedStatus,
    selectedRequestTypes,
    selectedApprovalLevels,
    filterNames,
    setSelectedStatus,
    setFilterNames,
    setSelectedRequestTypes,
    setSelectedApprovalLevels,
    selectedRequester,
    selectedPartner,
    setSelectedPartner,
    setSelectedRequester,
    partnerDetails,
    requesterDetails,
    onClickAffiliationCheckBox,
    onClickRequesterCheckBox,
    setAllFilterNamesModal,
    allFilterNamesModal,
    filterColumnList,
    setFilterColumnList,
    onClickCrossButton,
    boolCompletedAssets = true,
    filteredRequesterArray,
    filteredAffiliationArray,
    activeAssetsFilterCount,
  } = props;

  const [statusDetails, setStatusDetails] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [viewRequesterMore, setViewRequesterMore] = useState(false);
  const [viewAffiliationMore, setViewAffiliationMore] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const [approvalLevels, setApprovalLevels] = useState(null);
  const [filternamesRequesType, setfilternamesRequest] = useState([]);
  const [filternamesApprovalLevel, setfilternamesApprovalLevel] = useState([]);
  const [newRequesterData, setNewRequesterData] = useState(() => requesterDetails?.slice(0, 6));
  const [newAffiliationData, setNewAffiliationData] = useState(() => partnerDetails?.slice(0, 6));
  const selectedRequesterData = viewRequesterMore ? requesterDetails : newRequesterData;
  const selectedAffiliationData = viewAffiliationMore ? partnerDetails : newAffiliationData;

  const [searchTerm, setSearchTerm] = useState("");

  const filterAffiliationData = filteredAffiliationArray?.map((name, index) => ({
    id: index + 1,
    name,
  }));

  useEffect(() => {
    if (requesterDetails !== null) {
      setNewRequesterData(requesterDetails.slice(0, 6));
    }

    if (partnerDetails !== null) {
      setNewAffiliationData(partnerDetails.slice(0, 6));
    }
  }, [requesterDetails, partnerDetails]);

  useEffect(() => {
    if (statusDetails == null) {
      getStatusAssets().then((response) => {
        if (response !== false) {
          setStatusDetails(response);
        }
      });
    }

    getRequestTypesAssets().then((response) => {
      if (response !== false) {
        setRequestDetails(response);
      }
    });

    getApprovalLevels().then((response) => {
      if (response !== false) {
        setApprovalLevels(response);
      }
    });
  }, []);

  const handleViewMoreRequester = () => {
    setViewRequesterMore((prevState) => !prevState);
  };

  const handleViewMoreAffiliation = () => {
    setViewAffiliationMore((prevState) => !prevState);
  };

  const handleStatusView = () => {
    setViewMore((prevState) => !prevState);
  };

  // status toggle check box//
  const toggleSelectionCheckbox = (event, status) => {
    const FilternamesList = [...filterNames];
    const modalFIlterNames = [...allFilterNamesModal];
    const { value, checked } = event.target;

    if (checked) {
      FilternamesList.push(status);
      modalFIlterNames.push(status);
      setFilterNames(FilternamesList);
      setAllFilterNamesModal(FilternamesList);
      setSelectedStatus((prevState) => {
        return [...prevState, value];
      });
    } else {
      FilternamesList.pop(status);
      modalFIlterNames.pop(status);
      setFilterNames((prev) => {
        return prev.filter((state) => state.wrike_status_id !== value);
      });
      setAllFilterNamesModal((prev) => {
        return prev.filter((state) => state.wrike_status_id !== value);
      });
      setSelectedStatus((prevState) => {
        return prevState.filter((a) => a !== value);
      });
    }
  };

  const handleAllChildCheckboxes = (event) => {
    const selectedStatuss = statusDetails.mainStatus.filter((a) => a.name === event.target.value);
    const selectedData = selectedStatuss[0].status.map((selected) => selected);
    const FilternamesList = [...filterNames];
    const modalFIlterNames = [...allFilterNamesModal];
    if (event.target.checked) {
      const selectedValues = selectedStatuss[0].status.map((selected) => {
        return selected.wrike_status_id;
      });
      selectedData.forEach((item) => {
        const existItem = FilternamesList.find(
          (data) => data.wrike_status_id === item.wrike_status_id
        );
        const modalExistItem = modalFIlterNames.find(
          (data) => data.wrike_status_id === item.wrike_status_id
        );
        if (!modalExistItem) {
          modalFIlterNames.push(item);
        }
        if (!existItem) {
          FilternamesList.push(item);
        }
      });
      setFilterNames(FilternamesList);
      setAllFilterNamesModal(modalFIlterNames);
      setSelectedStatus((prevState) => {
        return [...prevState, ...selectedValues];
      });
      setFilterColumnList([...filterColumnList, selectedStatuss[0]]);
    } else {
      const unSelectedValues = selectedData.map((selected) => {
        return selected.wrike_status_id;
      });
      const updateListArray = filterColumnList.filter(
        (item) => item.name !== selectedStatuss[0].name
      );

      const filterListName = FilternamesList.filter((item) => {
        const isexistItem = selectedData.find(
          (items) => items.wrike_status_id === item.wrike_status_id
        );
        if (isexistItem) {
          return item !== isexistItem;
        }
        return item;
      });

      const filterListNameModal = modalFIlterNames.filter((item) => {
        const isexistItem = selectedData.find(
          (items) => items.wrike_status_id === item.wrike_status_id
        );
        if (isexistItem) {
          return item !== isexistItem;
        }
        return item;
      });

      setFilterNames(filterListName);
      setAllFilterNamesModal(filterListNameModal);
      setFilterColumnList(updateListArray);
      setSelectedStatus((prevState) => {
        return prevState.filter((a) => !unSelectedValues.includes(a));
      });
    }
  };

  const toggleSelectionRequestCheckbox = (event) => {
    const filterlist = [...filternamesRequesType];
    if (event.target.checked) {
      setSelectedRequestTypes((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.concat(event.target.value);
      });
      filterlist.push(event.target.value);
      setfilternamesRequest(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);
      setfilternamesRequest(filterlist);
      setSelectedRequestTypes((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const toggleSelectionApprovalCheckbox = (event) => {
    const filterlist = [...filternamesApprovalLevel];
    if (event.target.checked) {
      setSelectedApprovalLevels((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.concat(event.target.value);
      });
      filterlist.push(event.target.value);
      setfilternamesApprovalLevel(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);
      setfilternamesApprovalLevel(filterlist);
      setSelectedApprovalLevels((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const handleClearAll = () => {
    setSelectedStatus([]);
    setSelectedRequestTypes([]);
    setSelectedApprovalLevels([]);
    setFilterColumnList([]);
    setSelectedPartner([]);
    setSelectedRequester([]);

    const filterNameList = [...filterNames];
    const filterListName = filterNameList.filter((item) => {
      const isexistItem = allFilterNamesModal.find((items) => items === item);
      if (isexistItem) {
        return item !== isexistItem;
      }
      return item;
    });
    setFilterNames(filterListName);
    setAllFilterNamesModal([]);
    sessionStorage.clear();
  };

  const handleOnSearch = (value) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchTerm(value);
  };

  const searchFn = (item) => {
    if (typeof item?.name === "object") {
      return false;
    }
    return item?.name?.toLowerCase().includes(searchTerm?.toLowerCase());
    // if (!!searchTerm && searchTerm !== "" && !!item) {
    //   if (typeof item?.name === "object") {
    //     console.log("object");
    //     return false;
    //   }
    //   return item?.name?.toLowerCase().includes(searchTerm?.toLowerCase());
    // }
    // console.log(item);
    // return false;
  };

  const filteredOptions = filterAffiliationData?.filter(searchFn);

  const handleOnHover = () => {};

  // const filterOptions = (options, inputValue) => {
  //   console.log("🚀 ~ file: FilterModal.js:272 ~ filterOptions ~ options:", options);
  //   // return options.filter((option) => {
  //   return options.name.toLowerCase().includes(inputValue.toLowerCase());
  //   // });
  // };

  const handleOnSelectRequester = (item) => {
    setSelectedRequester([...selectedRequester, item.name]);
    setFilterNames([...filterNames, item.name]);
    setAllFilterNamesModal([...allFilterNamesModal, item.name]);

    const existingItemFilterNames = filterNames.filter((val) => val === item.name);
    const existingItemAllFilterNames = allFilterNamesModal.filter((val) => val === item.name);
    const existingItemRequesterNames = selectedRequester.filter((val) => val === item.name);

    filterNames.pop(existingItemFilterNames);
    allFilterNamesModal.pop(existingItemAllFilterNames);
    selectedRequester.pop(existingItemRequesterNames);
  };

  const handleOnSelectAffiliation = (item) => {
    setSelectedPartner([...selectedPartner, item.name]);
    setFilterNames([...filterNames, item.name]);
    setAllFilterNamesModal([...allFilterNamesModal, item.name]);

    const existingItemFilterNames = filterNames.filter((val) => val === item.name);
    const existingItemAllFilterNames = allFilterNamesModal.filter((val) => val === item.name);
    const existingItemRequesterNames = selectedPartner.filter((val) => val === item.name);

    filterNames.pop(existingItemFilterNames);
    allFilterNamesModal.pop(existingItemAllFilterNames);
    selectedPartner.pop(existingItemRequesterNames);
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return <span style={{ display: "block", textAlign: "left" }}> {item.name}</span>;
  };

  const formatResultAffiliation = (item) => {
    return <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>;
  };

  return (
    <Dialog
      className="dialogs"
      open={openModal}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
      id="filterDialog">
      <DialogTitle>
        <Grid container pr={0} pl={0}>
          <Grid item md={11.5} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontFamily: "GothamBold",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#4a4a4a",
              }}
              pt={0.4}>
              FILTER
            </Typography>
          </Grid>
          <Grid item md={0.4}>
            <IconButton onClick={onClickCrossButton}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", height: "1px" }} />
        </Grid>
      </DialogTitle>

      <DialogContent>
        <div className="c-overview-container__pop-over__filter-dialog-content">
          {boolCompletedAssets && (
            <>
              <Grid className="c-overview-container__pop-over__grid-divider" container mt={1.8}>
                <Grid md={12} mb={1.5}>
                  <Heading
                    title="Status"
                    variant="body"
                    titleStyle="c-overview-container__pop-over__typography-status"
                  />
                </Grid>
                <Grid container>
                  <Grid item md={6}>
                    {statusDetails !== null &&
                      statusDetails.mainStatus
                        .filter(
                          (a) => a.name !== "Completed/Delivered" && a.name !== "Under Approval"
                        )
                        .map((status) => (
                          <Grid item md={11} mb={1.5}>
                            <FormGroup>
                              <FormControlLabel
                                checked={status.status.every((item) => {
                                  return selectedStatus.some(
                                    (value) => value === item.wrike_status_id
                                  );
                                })}
                                classes={{
                                  label: "c-overview-container__pop-over__filter-check-modal",
                                }}
                                control={
                                  <Checkbox
                                    sx={{
                                      "&$checked": {
                                        color: "red",
                                        background: "yellow",
                                      },
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankSharpIcon
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                          color: "#216FB4",
                                        }}
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxSharpIcon
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                          color: "#216FB4",
                                        }}
                                      />
                                    }
                                  />
                                }
                                label={
                                  status.name === "Intake" ? `Request ${status.name}` : status.name
                                }
                                onChange={handleAllChildCheckboxes}
                                value={status.name}
                              />
                            </FormGroup>
                            {viewMore && (
                              <Box pl={2.98}>
                                <FormGroup>
                                  {status?.status?.map((subStatus) => (
                                    <FormControlLabel
                                      classes={{
                                        label: "c-overview-container__pop-over__filter-check-part",
                                      }}
                                      sx={{
                                        pt: 0.8,
                                        pl: 2.75,
                                      }}
                                      control={
                                        <Checkbox
                                          onChange={(e) => toggleSelectionCheckbox(e, subStatus)}
                                          checked={selectedStatus.includes(
                                            subStatus.wrike_status_id
                                          )}
                                          value={subStatus.wrike_status_id}
                                          icon={
                                            <CheckBoxOutlineBlankSharpIcon
                                              sx={{
                                                width: "16px",
                                                height: "16px",
                                                color: "#216FB4",
                                              }}
                                            />
                                          }
                                          checkedIcon={
                                            <DoneSharpIcon
                                              sx={{
                                                margin: "2px",
                                                width: "12px",
                                                height: "12px",
                                                color: "#216FB4",
                                                boxShadow: "0px 0px 0px 1px #216FB4 inset",
                                              }}
                                            />
                                          }
                                        />
                                      }
                                      label={subStatus.name}
                                    />
                                  ))}
                                </FormGroup>
                              </Box>
                            )}
                          </Grid>
                        ))}
                  </Grid>

                  <Grid item md={6}>
                    {statusDetails !== null &&
                      statusDetails.mainStatus
                        .filter((a) => a.name === "Under Approval")
                        .map((status) => (
                          <Grid item md={11} mb={1.5}>
                            <FormGroup>
                              <FormControlLabel
                                checked={status.status.every((item) => {
                                  return selectedStatus.some(
                                    (value) => value === item.wrike_status_id
                                  );
                                })}
                                classes={{
                                  label: "c-overview-container__pop-over__filter-check-modal",
                                }}
                                control={
                                  <Checkbox
                                    icon={
                                      <CheckBoxOutlineBlankSharpIcon
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                          color: "#216FB4",
                                        }}
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxSharpIcon
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                          color: "#216FB4",
                                        }}
                                      />
                                    }
                                  />
                                }
                                label={`Request ${status.name}`}
                                onChange={handleAllChildCheckboxes}
                                value={status.name}
                              />
                            </FormGroup>
                            {viewMore && (
                              <Box pl={2.98}>
                                <FormGroup>
                                  {status?.status?.map((subStatus) => (
                                    <FormControlLabel
                                      classes={{
                                        label: "c-overview-container__pop-over__filter-check-part",
                                      }}
                                      sx={{
                                        pt: 0.8,
                                        pl: 2.75,
                                      }}
                                      control={
                                        <Checkbox
                                          onChange={(e) => toggleSelectionCheckbox(e, subStatus)}
                                          checked={selectedStatus.includes(
                                            subStatus.wrike_status_id
                                          )}
                                          value={subStatus.wrike_status_id}
                                          icon={
                                            <CheckBoxOutlineBlankSharpIcon
                                              sx={{
                                                width: "16px",
                                                height: "16px",
                                                color: "#216FB4",
                                              }}
                                            />
                                          }
                                          checkedIcon={
                                            <DoneSharpIcon
                                              sx={{
                                                margin: "2px",
                                                width: "12px",
                                                height: "12px",
                                                color: "#216FB4",
                                                boxShadow: "0px 0px 0px 1px #216FB4 inset",
                                              }}
                                            />
                                          }
                                        />
                                      }
                                      label={subStatus.name}
                                    />
                                  ))}
                                </FormGroup>
                              </Box>
                            )}
                          </Grid>
                        ))}
                  </Grid>
                </Grid>

                <LinkButton
                  title={viewMore ? "View Less" : "View All"}
                  buttonStyle="c-overview-container__pop-over__view-and-clear-all-btn"
                  onClick={handleStatusView}
                />
              </Grid>

              <Divider sx={{ width: "100%", height: "1px" }} />
            </>
          )}

          <Grid className="c-overview-container__pop-over__grid-divider" container mt={3}>
            <Grid md={12} mb={1.5}>
              <Heading
                title="Request Type"
                variant="body"
                titleStyle="c-overview-container__pop-over__typography-status"
              />
            </Grid>

            <Grid item md={12} mb={1.5}>
              <Grid container>
                {requestDetails !== null &&
                  requestDetails.map((requestTypes) => (
                    <FormControlInput
                      gridVal={4}
                      label={requestTypes.name}
                      labelStyle="c-overview-container__pop-over__check-labels"
                      mainContainerStyle="c-overview-container__pop-over__filter-check"
                      checkBoxStyle="c-overview-container__pop-over__checkbox-sharp-Ic"
                      onChange={toggleSelectionRequestCheckbox}
                      value={requestTypes.internal_name}
                      checked={selectedRequestTypes.includes(requestTypes.internal_name)}
                    />
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ width: "100%", height: "1px" }} />

          <Grid className="c-overview-container__pop-over__grid-divider" container mt={3}>
            <Grid md={12} mb={1.5}>
              <Heading
                title="Approval Level"
                variant="body"
                titleStyle="c-overview-container__pop-over__typography-status"
              />
            </Grid>

            <Grid item md={12} mb={1.5}>
              <Grid container>
                {approvalLevels !== null &&
                  approvalLevels.map((approvalLevel) => (
                    <FormControlInput
                      gridVal={4}
                      label={approvalLevel.name}
                      labelStyle="c-overview-container__pop-over__check-labels"
                      mainContainerStyle="c-overview-container__pop-over__filter-check"
                      checkBoxStyle="c-overview-container__pop-over__checkbox-sharp-Ic"
                      onChange={toggleSelectionApprovalCheckbox}
                      value={approvalLevel.internal_name}
                      checked={selectedApprovalLevels.includes(approvalLevel.internal_name)}
                    />
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ width: "100%", height: "1px" }} />

          <Grid container mt={3} className="c-overview-container__pop-over__grid-divider">
            <Grid item md={12} mb={1.5}>
              <Heading
                title="Partner, Affiliation or Consortium"
                variant="body"
                titleStyle="c-overview-container__pop-over__typography-status"
              />
            </Grid>

            <Grid item md={12} mb={2}>
              <Typography className="c-overview-container__pop-over__typography-commonly-searched__description">
                Looking for assets by a particular partner, affiliation, or consortium? Use search.
              </Typography>
            </Grid>

            <Grid
              item
              md={9}
              mb={2}
              // onClick={handleClickAffiliation}
            >
              <ReactSearchAutocomplete
                items={filteredOptions}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                // inputSearchString={searchTerm}
                // inputDebounce={500}
                // filterOptions={filterOptions}
                onSelect={handleOnSelectAffiliation}
                onFocus={handleOnFocus}
                formatResult={formatResultAffiliation}
                styling={{
                  zIndex: 2,
                  width: 300,
                  iconColor: "#216fb4",
                }}
                // showIcon={false}
                placeholder="Search"
              />
            </Grid>

            <Grid md={6} />

            <Grid md={12} mt={2} mb={2}>
              <Typography className="c-overview-container__pop-over__typography-commonly-searched">
                Commonly Searched
              </Typography>
            </Grid>

            <Grid item md={12}>
              <Grid container>
                {selectedAffiliationData !== null &&
                  selectedAffiliationData?.map((requestTypes) => {
                    return (
                      <Grid item md={4} mb={1.5}>
                        <FormGroup>
                          <FormControlLabel
                            classes={{
                              label: "c-overview-container__pop-over__filter-check-part",
                            }}
                            sx={{ pt: 0.8, pl: 2.75 }}
                            control={
                              <Checkbox
                                onChange={onClickAffiliationCheckBox}
                                value={requestTypes}
                                checked={selectedPartner.includes(requestTypes)}
                                icon={
                                  <CheckBoxOutlineBlankSharpIcon
                                    sx={{
                                      width: "16px",
                                      height: "16px",
                                      color: "#216FB4",
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <CheckBoxSharpIcon
                                    sx={{
                                      width: "16px",
                                      height: "16px",
                                      color: "#216FB4",
                                    }}
                                  />
                                }
                              />
                            }
                            label={requestTypes}
                          />
                        </FormGroup>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>

            <LinkButton
              title={viewAffiliationMore ? "View Less" : "View All"}
              buttonStyle="c-overview-container__pop-over__view-and-clear-all-btn"
              onClick={handleViewMoreAffiliation}
            />
          </Grid>

          <Divider sx={{ width: "100%", height: "1px" }} />

          <Grid container mt={3} className="c-overview-container__pop-over__grid-divider">
            <Grid item md={12} mb={1.5}>
              <Heading
                title="Requester"
                variant="body"
                titleStyle="c-overview-container__pop-over__typography-status"
              />
            </Grid>
            <Grid item md={12} mb={2}>
              <Typography className="c-overview-container__pop-over__typography-commonly-searched__description">
                Looking for assets by a requester? Use search.
              </Typography>
            </Grid>
            <Grid item md={6} mb={2}>
              <ReactSearchAutocomplete
                items={filteredRequesterArray}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelectRequester}
                // filterOptions={filterOptions}
                onFocus={handleOnFocus}
                formatResult={formatResult}
                styling={{
                  zIndex: 2,
                  width: 300,
                  iconColor: "#216fb4",
                }}
                // showIcon={false}
                placeholder="Search"
              />
            </Grid>
            <Grid md={6} />

            <Grid md={12} mt={2} mb={2}>
              <Typography className="c-overview-container__pop-over__typography-commonly-searched">
                Commonly Searched
              </Typography>
            </Grid>

            <Grid item md={12} mb={1.5}>
              <Grid container>
                {selectedRequesterData !== null &&
                  selectedRequesterData?.map((requesterTypes) => (
                    <Grid item md={4} mb={1.5}>
                      <FormGroup>
                        <FormControlLabel
                          classes={{
                            label: "c-overview-container__pop-over__filter-check-part",
                          }}
                          sx={{
                            pt: 0.8,
                            pl: 2.75,
                          }}
                          control={
                            <Checkbox
                              onChange={onClickRequesterCheckBox}
                              value={requesterTypes.name}
                              checked={selectedRequester.includes(requesterTypes.name)}
                              icon={
                                <CheckBoxOutlineBlankSharpIcon
                                  sx={{
                                    width: "16px",
                                    height: "16px",
                                    color: "#216FB4",
                                  }}
                                />
                              }
                              checkedIcon={
                                <CheckBoxSharpIcon
                                  sx={{
                                    width: "16px",
                                    height: "16px",
                                    color: "#216FB4",
                                  }}
                                />
                              }
                            />
                          }
                          label={requesterTypes.name}
                        />
                      </FormGroup>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            <LinkButton
              title={viewRequesterMore ? "View Less" : "View All"}
              buttonStyle="c-overview-container__pop-over__view-and-clear-all-btn"
              onClick={handleViewMoreRequester}
            />
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Grid container className="c-overview-container__pop-over__grid-divider">
          <Grid item md={12} px={2}>
            <Divider
              sx={{
                width: "100%",
                height: "1px",
                color: "#4a4a4a",
              }}
            />
          </Grid>

          <Grid item md={2} mt={2}>
            <Button className="underlined clearButton" onClick={handleClearAll}>
              Clear All
            </Button>
          </Grid>

          <Grid md={6.2} />

          <Grid
            md={3.8}
            mt={2}
            mb={2}
            sx={{ display: "flex", justifyContent: "flex-end", paddingRight: 1.4 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              className="c-overview-container__pop-over__btn-col">
              <Typography className="c-overview-container__pop-over__btn-col__typogrpahy-show-asset-btn">
                {`Show ${activeAssetsFilterCount} asset${activeAssetsFilterCount > 1 ? "s" : ""}`}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

FilterModal.propTypes = {
  openModal: PropTypes.arrayOf.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedStatus: PropTypes.arrayOf.isRequired,
  selectedRequestTypes: PropTypes.arrayOf.isRequired,
  selectedApprovalLevels: PropTypes.arrayOf.isRequired,
  filterNames: PropTypes.arrayOf.isRequired,
  setSelectedStatus: PropTypes.func.isRequired,
  setFilterNames: PropTypes.func.isRequired,
  setSelectedRequestTypes: PropTypes.func.isRequired,
  setSelectedApprovalLevels: PropTypes.func.isRequired,
  selectedPartner: PropTypes.arrayOf.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  selectedRequester: PropTypes.arrayOf.isRequired,
  setSelectedRequester: PropTypes.func.isRequired,
  setAllFilterNamesModal: PropTypes.func.isRequired,
  onClickAffiliationCheckBox: PropTypes.func.isRequired,
  onClickRequesterCheckBox: PropTypes.func.isRequired,
  requesterDetails: PropTypes.arrayOf.isRequired,
  partnerDetails: PropTypes.arrayOf.isRequired,
  filteredAffiliationArray: PropTypes.arrayOf.isRequired,
  allFilterNamesModal: PropTypes.arrayOf.isRequired,
  filteredRequesterArray: PropTypes.arrayOf.isRequired,
  filterColumnList: PropTypes.arrayOf.isRequired,
  setFilterColumnList: PropTypes.func.isRequired,
  onClickCrossButton: PropTypes.func.isRequired,
  activeAssetsFilterCount: PropTypes.number.isRequired,
  boolCompletedAssets: PropTypes.bool.isRequired,
};

export default FilterModal;
