import React from "react";
import { Container, Typography, Button, Box, Link } from "@mui/material";
import AssetTrackerLogo from "images/logos/AssetTrackerLogo.webp";
// import oops from "images/Icons/oops.png";

function PageNotFound() {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}>
      <img src={AssetTrackerLogo} alt="lp" width="30%" height="25%" />

      <Box
        sx={{
          display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          alignItems: "baseline",
        }}>
        <Typography
          sx={{ fontFamily: "GothamBold", fontWeight: "900", fontSize: "3em", color: "#2d2d2e" }}>
          404- Page Not Found
        </Typography>

        <Typography
          sx={{
            fontFamily: "GothamBold",
            marginLeft: "50px",
            marginTop: "10px",
            fontWeight: 500,
            fontSize: "1.1em",
            color: "#484852",
          }}>
          We are sorry, the page you were looking for is not found here.
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          marginTop: "20px",
          borderRadius: "25px",
          padding: "15px 20px 15px 20px",
          cursor: "pointer",
          background: "#262262",
        }}>
        <Link
          href="/home"
          sx={{
            fontFamily: "GothamBook",
            fontSize: "15px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: " normal",
            color: "white",
          }}>
          GO TO HOME PAGE
        </Link>
      </Button>
    </Container>
  );
}

export default PageNotFound;
