import React, { useState } from "react";
import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useLocation } from "react-router-dom";
import { Reorder, ViewStream } from "@mui/icons-material";

// Local Imports
import Detailview from "components/Detail/Detailview";
import Overview from "components/Extras/Overview";
import Footer from "components/Footer/Footer";

function Activeassets() {
  const location = useLocation();

  const tabId = location?.state?.tabId === (null || undefined) ? 0 : location?.state?.tabId;

  const [selectedTab, setSelectedTab] = useState(() => tabId);

  const handleOnClickDetaiView = () => {
    setSelectedTab(0);
  };

  const handleOnClickOverView = () => {
    setSelectedTab(1);
  };

  // check curenLoginUserRoles
  const userRoles = localStorage.getItem("user_roles");

  const isUserRolesExecutive = userRoles.includes("executive");

  const os = navigator.userAgent;
  let system = "window";
  if (os.search("Mac") !== -1) {
    system = "mac";
  }

  return (
    <Box className="b-active-assets-container">
      {selectedTab === 0 ? (
        <Box className="detail">
          <Detailview />
        </Box>
      ) : (
        <Box>
          <Overview />
        </Box>
      )}

      <Container sx={{ position: "relative" }}>
        {selectedTab === 1 && (
          <>
            <Box className="b-active-assets-container__grid_progress-border-indicator" md={0.12} />
            <Box
              className="b-active-assets-container__grid_progress-border-indicator-sop"
              md={0.12}
            />
            <Box
              className="b-active-assets-container__grid_progress-border-indicator-extended"
              md={0.12}
            />
          </>
        )}
        <Divider sx={{ width: 1, height: 1, marginTop: 2.3 }} />
        {selectedTab === 1 && (
          <Grid sx={{ marginTop: "-5px" }} container alignItems="center">
            <Grid item md={system === "mac" ? 2.55 : 2.43} />
            <Grid item md={system === "mac" ? 5.45 : 5.58}>
              <Typography
                sx={{
                  fontFamily: "GothamBook",
                  fontSize: "12px",
                  lineHeight: "1.67",
                  letterSpacing: " -0.17px",
                  color: "#262162",
                  marginLeft: system === "mac" ? "-5px" : "0px",
                  marginTop: "7px",
                }}>
                Submission Date
              </Typography>
            </Grid>
            <Grid item md={system === "mac" ? 1.97 : 1.89}>
              <Typography
                sx={{
                  fontFamily: "GothamBook",
                  fontSize: "12px",
                  lineHeight: "1.67",
                  letterSpacing: " -0.17px",
                  color: "#262162",
                  marginTop: "7px",
                }}>
                SOP Due Date
              </Typography>
            </Grid>
            <Grid item md={system === "mac" ? 1.4 : 2}>
              <Typography
                sx={{
                  fontFamily: "GothamBook",
                  fontSize: "12px",
                  lineHeight: "1.67",
                  letterSpacing: " -0.17px",
                  color: "#262162",
                  marginTop: "7px",
                  display: "flex",
                  justifyContent: "center",
                }}>
                Extended
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container alignItems="center">
          <Grid item md={10.5}>
            <Typography
              variant="body"
              className="b-active-assets-container__typography-all-assets-are-uniq">
              *All assets are unique. Typical durations are based on past projects and may not apply
              to yours.
            </Typography>
          </Grid>
          {isUserRolesExecutive && (
            <Grid item md={1.5} sx={{ marginTop: 2 }}>
              <Box sx={{ display: "block", justifyContent: "flex-end" }}>
                <Button
                  disabled={selectedTab === 0}
                  onClick={handleOnClickDetaiView}
                  sx={{ display: "flex", padding: "4px 0px", cursor: "pointer" }}>
                  {selectedTab === 0 ? (
                    <RadioButtonCheckedIcon
                      className={selectedTab === 0 && "b-active-assets-container__disable-button"}
                      sx={{
                        height: "24px",
                        width: "24px",
                        lineHeight: 0,
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      className={selectedTab === 0 && "b-active-assets-container__disable-button"}
                      sx={{
                        height: "24px",
                        width: "24px",
                        lineHeight: 0,
                      }}
                    />
                  )}
                  <ViewStream
                    sx={{
                      height: "24px",
                      width: "24px",
                      lineHeight: 0,
                      color: selectedTab === 0 ? "#216fb4" : "#474a4c",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "GothamMedium",
                      fontSize: 10,
                      fontWeight: 500,
                      lineHeight: 0,
                      letterSpacing: -0.08,
                      color: selectedTab === 0 ? "#216fb4" : "#474a4c",
                    }}>
                    DETAIL
                  </Typography>
                </Button>
                <Button
                  disabled={selectedTab === 1}
                  onClick={handleOnClickOverView}
                  sx={{ display: "flex", padding: "4px 0px", cursor: "pointer" }}>
                  {selectedTab === 1 ? (
                    <RadioButtonCheckedIcon
                      className={selectedTab === 1 && "b-active-assets-container__disable-button"}
                      sx={{
                        height: "24px",
                        width: "24px",
                        lineHeight: 0,
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      className={selectedTab === 1 && "b-active-assets-container__disable-button"}
                      sx={{
                        height: "24px",
                        width: "24px",
                        lineHeight: 0,
                      }}
                    />
                  )}
                  <Reorder
                    sx={{
                      height: "24px",
                      width: "24px",
                      lineHeight: 0,
                      color: selectedTab === 1 ? "#216fb4" : "#474a4c",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "GothamMedium",
                      fontSize: 10,
                      fontWeight: 500,
                      lineHeight: 0,
                      letterSpacing: -0.08,
                      color: selectedTab === 1 ? "#216fb4" : "#474a4c",
                    }}>
                    OVERVIEW
                  </Typography>
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>

      <Footer
        FooterCenterA="FooterCenterActive"
        AllofUsandtheAlA="All-of-Us-and-the-Al"
        textstyle4A="text-style-4"
      />
    </Box>
  );
}
export default Activeassets;
